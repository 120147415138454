<template>
    <div class="certification-wrap">
        <van-nav-bar
            title="实名认证"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="certification-main-wrap">
            <van-cell-group inset class="info-cell-group">
                <van-field v-model="certificationParams.name" center :border="false" clearable label="姓名" placeholder="请输入" autocomplete="off" />
                <van-field v-model="certificationParams.card" center :border="false" clearable label="身份证号" placeholder="请输入" :formatter="formatter" autocomplete="off" maxlength="18" />
            </van-cell-group>
            <div class="upload-box">
                <h3>上传身份证</h3>
                <van-uploader class="identity-photo-wrap" v-model="frontFileList" :preview-full-image='false' :deletable="false" :before-read="beforeUploadFront" :after-read="afterUploadFront">
                    <div class="photo-box">
                        <van-image :src="themeImgs.myIdentityCardBgImg" />
                        <div class="upload-btn-box">
                            <van-image :src="themeImgs.myUploadBtnImg" />
                            <span>人脸面</span>
                        </div>
                    </div>
                </van-uploader>
                <van-uploader class="identity-photo-wrap" v-model="backFileList" :preview-full-image='false' :deletable="false" :before-read="beforeUploadBack" :after-read="afterUploadBack">
                    <div class="photo-box">
                        <van-image :src="themeImgs.myIdentityCardBgImg1" />
                        <div class="upload-btn-box">
                            <van-image :src="themeImgs.myUploadBtnImg" />
                            <span>国徽面</span>
                        </div>
                    </div>
                </van-uploader>
            </div>
            <div class="next-btn-box">
                <van-button block @click="handleNext">{{isOpenFace == 1 ? '下一步' : '提交'}}</van-button>
            </div>
        </div>
        <van-overlay class-name="certification-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
    </div>
</template>

<script>
import { reactive, ref,computed, onMounted } from 'vue'
import {useRouter} from 'vue-router'
import {uploadImage,doCertification} from '@/api/api'
import { Toast } from 'vant'
import { useStore } from "vuex"

export default {
    setup () {
        const $router = useRouter()
        const store = useStore();
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        // 是否开启人脸识别  1为开启，0为关闭
        const isOpenFace = ref(store.state.publicConfig.is_human_face)
        onMounted(() => {
            handleGetCertificationConfig()
        })
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        // 存储验证参数
        const certificationParams = reactive({
            name: '',
            card: '',
            card_just_pic: '', // 身份证正面
            card_back_pic: '', // 身份证反面
        })
        // 存储上传身份证正面后得到的文件流
        const frontFileList = ref([])
        // 上传身份证正面前
        const beforeUploadFront = () => {
            showOverlay.value = true
            frontFileList.value = []
            certificationParams.card_just_pic = ''
            return true
        }
        // 上传身份证反面前
        const beforeUploadBack = () => {
            showOverlay.value = true
            backFileList.value = []
            certificationParams.card_back_pic = ''
            return true
        }
        // 上传身份证正面后
        const afterUploadFront = (file) => {
            file.status = 'uploading';
            file.message = '上传中...';
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'card')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    certificationParams.card_just_pic = res.data.path
                    file.status = 'success';
                    showOverlay.value = false
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    showOverlay.value = false
                    certificationParams.card_just_pic = ''
                    frontFileList.value = []
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                file.status = 'failed';
                file.message = '上传失败';
                showOverlay.value = false
                certificationParams.card_just_pic = ''
                frontFileList.value = []
                Toast.fail('上传异常,请重新上传')
            })
        };
        // 存储上传身份证反面后得到的文件流
        const backFileList = ref([])
        // 上传身份证反面后
        const afterUploadBack = (file) => {
            file.status = 'uploading'
            file.message = '上传中...'
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'card')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    certificationParams.card_back_pic = res.data.path
                    file.status = 'success'
                    showOverlay.value = false
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    showOverlay.value = false
                    certificationParams.card_back_pic = ''
                    backFileList.value = []
                    Toast.fail(res.msg)
                }
            }).catch(err => {
                file.status = 'failed';
                file.message = '上传失败';
                showOverlay.value = false
                certificationParams.card_back_pic = ''
                backFileList.value = []
                Toast.fail('上传异常,请重新上传')
            })
        };
        // 是否显示蒙层
        const showOverlay = ref(false)
        // 点击下一步
        const handleNext = () => {
            if(!certificationParams.name) {
                return Toast.fail('请输入真实姓名')
            } else if(!certificationParams.card) {
                return Toast.fail('请输入身份证号')
            } else if(!certificationParams.card_just_pic) {
                return Toast.fail('请上传身份证正面')
            } else if(!certificationParams.card_back_pic) {
                return Toast.fail('请上传身份证反面')
            } 
            if (isOpenFace.value == 1) {
                window.localStorage.setItem('certificationParams',JSON.stringify(certificationParams))
                $router.push('/identityVerification')
            } else {
                handleConfirm()
            }
        }
        const handleConfirm = () => {
            doCertification(certificationParams).then(res => {
                if(res.result == 200000) {
                    Toast.success('认证信息上传成功，请耐心等待审核')
                    $router.push('/home')
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            onClickLeft,
            certificationParams,
            frontFileList,
            afterUploadFront,
            backFileList,
            afterUploadBack,
            showOverlay,
            handleNext,
            formatter,
            beforeUploadFront,
            beforeUploadBack,
            themeImgs,
            isOpenFace
        }
    }
}
</script>

<style lang="less" scoped>
.certification-wrap {
    .certification-main-wrap {
        height: calc(100vh - 46px);
        background: var(--mainBgColor);
        :deep(.info-cell-group) {
            padding: 24px 0;
            background: var(--mainBgColor);
            .van-cell {
                height: 50px;
                border-radius: 8px;
                &:last-child {
                    margin-top: 18px;
                }
                .van-field__label {
                    label {
                        font-weight: 500;
                        font-size: 15px;
                    }
                    
                }
            }
        }
        .upload-box {
            padding: 0 16px;
            h3 {
                margin-bottom: 12px;
                font-weight: 500;
                font-size: 16px;
                color: var(--textCommonColor);
            }
            :deep(.identity-photo-wrap) {
                width: 100%;
                height: 154px;
                background: var(--boxBgColor);
                margin-bottom: 10px;
                border-radius: 8px;
                overflow: hidden;
                .van-uploader__input-wrapper {
                    width: 100%;
                }
                .van-uploader__preview {
                    position: absolute;
                    width: 214px;
                    height: 118px;
                    margin: 0;
                    top: 18px;
                    left: 24px;
                    z-index: 1;
                    .van-uploader__preview-image {
                        width: 100%;
                        height: 100%;
                        background: #fff;
                        border-radius: 8px;
                    }
                }
                .photo-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 154px;
                    padding: 18px 24px;
                    .van-image {
                        width: 214px;
                        height: 118px;
                    }
                    .upload-btn-box {
                        display: flex;
                        flex-direction: column;
                        justify-items: center;
                        .van-image {
                            width: 49px;
                            height: 49px;
                            margin-bottom: 6px;
                        }
                        span {
                            font-weight: 500;
                            color: var(--textCommonColor);
                            opacity: 0.6;
                        }
                    }
                }
            }
        }
        .next-btn-box {
            padding: 0 16px;
            margin-top: 15px;
            border-radius: 8px;
            .van-button {
                border-radius: 8px;
            }
        }
    }
    .certification-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>