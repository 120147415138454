<template>
    <div class="my-wrap">
        <van-dialog class="personalCenter-dialog" v-model:show="showPersonalCenterDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleClosePersonalCenterDialog"></div>
            <div class="my-main-wrap">
                <div class="top-tab-wrap">
                    <div class="top-tab-box">
                        <div class="tab-item" :class="{'active':curTabIndex==1}" @click="curTabIndex=1"><span>用户资料</span></div>
                        <div class="tab-item" :class="{'active':curTabIndex==2}" @click="curTabIndex=2"><span>帮助中心</span></div>
                    </div>
                </div>
                <div class="my-content-wrap" v-show="curTabIndex==1">
                    <div class="content-top-wrap">
                        <div class="left-wrap">
                            <van-image class="my-avatar" round :src="memberInfo.avatar" :error-icon="require('../../assets/images/common/default-avatar.png')" />
                            <div class="btn-box">
                                <div class="btn" @click="showAvatarDialog=true">更换头像</div>
                                <div class="btn" @click="handleGetAbout">关于我们</div>
                            </div>
                        </div>
                        <div class="right-wrap">
                            <div class="info-item-box">
                                <img src="../../assets/images/my/nickname-icon.png" alt="" />
                                <div class="info">{{memberInfo.nick_name}}</div>
                                <img src="../../assets/images/my/edit-icon.png" alt="" @click="showNickNameDialog=true" />
                            </div>
                            <div class="info-item-box">
                                <img src="../../assets/images/my/account-icon.png" alt="" />
                                <div class="info">{{memberInfo.username}}</div>
                                <img src="../../assets/images/common/copy-icon.png" alt="" @click="copyAddress(memberInfo.username)" />
                            </div>
                            <div class="info-item-box">
                                <img src="../../assets/images/my/wallet-icon.png" alt="" />
                                <div class="info">{{memberInfo.wallet_address}}</div>
                                <img src="../../assets/images/common/copy-icon.png" alt="" @click="copyAddress(memberInfo.wallet_address)" />
                            </div>
                        </div>
                    </div>
                    <van-cell-group class="content-item-wrap" :border="false">
                        <van-cell title="转账" center is-link @click="showTransferDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/transfer-icon.png')" />
                            </template>
                        </van-cell>
                        <van-cell title="修改登录密码" center is-link @click="showUpdateLoginPasswordDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/lock-icon.png')" />
                            </template>
                        </van-cell>
                        <van-cell title="修改支付密码" center is-link @click="showUpdatePayPasswordDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/pay-icon.png')" />
                            </template>
                        </van-cell>
                        <van-cell title="资金记录" center is-link @click="showFundRecordDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/record-icon.png')" />
                            </template>
                        </van-cell>
                        <van-cell title="个人报表" center is-link @click="showPersonalReportDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/report-icon.png')" />
                            </template>
                        </van-cell>
                        <van-cell title="代理佣金" center is-link @click="showAgencyCommissionDialog=true">
                            <template #icon>
                                <van-image :src="require('../../assets/images/my/agent-icon.png')" />
                            </template>
                        </van-cell>
                        <div v-if="isShowDiv" class="logout-btn-box">
                            <van-button block @click="handleDoLogout">退出登录</van-button>
                        </div>
                    </van-cell-group>
                </div>
                <div class="my-content-wrap help-center" v-show="curTabIndex==2">
                    <div class="left-wrap">
                        <div class="left-item-box" :class="{'active':curHelpIndex==1}" @click="curHelpIndex=1">
                            <span>买币</span>
                        </div>
                        <div class="left-item-box" :class="{'active':curHelpIndex==2}" @click="curHelpIndex=2">
                            <span>充值</span>
                        </div>
                        <div class="left-item-box" :class="{'active':curHelpIndex==3}" @click="curHelpIndex=3">
                            <span>提现</span>
                        </div>
                    </div>
                    <div class="right-wrap">
                        <img v-show="curHelpIndex==1" src="../../assets/images/my/buy-tree-img.png" alt="">
                        <img v-show="curHelpIndex==2" src="../../assets/images/my/recharge-tree-img.png" alt="">
                        <img v-show="curHelpIndex==3" src="../../assets/images/my/withdraw-tree-img.png" alt="">
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 修改昵称 -->
        <van-dialog class="nickname-dialog" v-model:show="showNickNameDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showNickNameDialog=false"></div>
            <div class="input-box">
                <van-field v-model="newNickName" placeholder="请输入昵称" clearable />
            </div>
            <div class="btn-box">
                <van-button block @click="handleUpdateNickName">保存</van-button>
            </div>
        </van-dialog>
        <!-- 修改头像 -->
        <van-dialog class="avatar-dialog" v-model:show="showAvatarDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showAvatarDialog=false"></div>
            <div class="uploader-box">
                <van-uploader class="editPersonalInfo-uploader" :max-count="1" v-model="uploadFile" :after-read="afterUpload" />
            </div>
            <div class="btn-box">
                <van-button block @click="handleUpdateAvatar">保存</van-button>
            </div>
        </van-dialog>
        <!-- 关于我们 -->
        <van-dialog class="aboutus-dialog" v-model:show="showAboutUsDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showAboutUsDialog=false"></div>
            <p class="aboutUs-main-box">{{aboutContent}}</p>
        </van-dialog>
        <!-- 转账 -->
        <Transfer v-if="showTransferDialog" :handleCloseTransferDialog="handleCloseTransferDialog" />
        <!-- 修改登录密码 -->
        <ModifyLoginPassword v-if="isShowDiv && showUpdateLoginPasswordDialog" :handleCloseUpdateLoginPasswordDialog="handleCloseUpdateLoginPasswordDialog" />
        <!-- 修改支付密码 -->
        <ModifyPaymentPassword v-if="isShowDiv && showUpdatePayPasswordDialog" :handleCloseUpdatePayPasswordDialog="handleCloseUpdatePayPasswordDialog" />
        <!-- 资金记录 -->
        <FundRecord v-if="showFundRecordDialog" :handleCloseFundRecordDialog="handleCloseFundRecordDialog" />
        <!-- 个人报表 -->
        <PersonalReport v-if="showPersonalReportDialog" :handleClosePersonalReportDialog="handleClosePersonalReportDialog" />
        <!-- 代理佣金 -->
        <AgencyCommission v-if="showAgencyCommissionDialog" :handleCloseAgencyCommissionDialog="handleCloseAgencyCommissionDialog" />
    </div>
</template>

<script>
import { onMounted,ref,computed } from 'vue'
import useClipboard from 'vue-clipboard3'
import {Toast} from 'vant'
import Transfer from "@/views/My/Transfer"
import ModifyLoginPassword from "@/views/My/ModifyLoginPassword"
import ModifyPaymentPassword from "@/views/My/ModifyPaymentPassword"
import FundRecord from "@/views/My/FundRecord"
import PersonalReport from "@/views/My/PersonalReport"
import AgencyCommission from "@/views/My/AgencyCommission"
import {getMemberInfo,upNickName,upAvatar,uploadImage,getAbout,logout} from '@/api/api'
import { useRouter } from "vue-router"
import { useStore } from 'vuex'
import wsConnection from "@/utils/websocket"

export default {
    components: {
        Transfer,
        ModifyLoginPassword,
        ModifyPaymentPassword,
        FundRecord,
        PersonalReport,
        AgencyCommission
    },
    props:['handleClosePersonalCenterDialog'],
    setup () {
        const $router = useRouter()
        const store = useStore()
        const showPersonalCenterDialog = ref(true)
        const curTabIndex = ref(1)
        // 获取会员个人信息
        const handleGetMemberInfo = () => {
            getMemberInfo().then(res => {
                if(res.result == 200000) {
                    memberInfo.value = res.data
                    store.dispatch('saveUserInfosActions', res.data)
                }
            })
        }
        onMounted(() => {
            handleGetMemberInfo()
            ifFromOtherPlat()
        })
        const isShowDiv = ref(true)
        const ifFromOtherPlat = () => {
            // 是否从综合iframe或者其他第三方那过来的
            if(window.parent.frames['agIframe'] || localStorage.getItem('isFromThird')) {
                isShowDiv.value = false
            }
        }
        // 存储会员个人信息
        const memberInfo = ref({})
        // 跳转实名认证页面
        const openCertificationUrl = () => {
            if(memberInfo.value.is_name == 2) {
                return Toast.fail('已实名')
            } else if(memberInfo.value.is_name == 3) {
                return Toast.fail('审核中')
            } else {
                $router.push('/certification')
            }
        }
        // 复制钱包地址
        const { toClipboard } = useClipboard()
        const copyAddress = async (content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 退出登录
        const handleDoLogout = () => {
            logout().then(res => {
                if(res.result == 200000) {
                    Toast.success('退出成功')
                    localStorage.removeItem("loginInfos")
                    if(wsConnection.$ws) {
                        wsConnection.$ws.close()
                    }
                    // 针对综合的退出
                    if(window.parent.frames['agIframe']) {
                        window.parent.postMessage('退出登录', store.state.loginInfos.originUrl)
                    } else {
                        $router.push('/login');
                    }
                }
            })
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 是否显示修改昵称弹框
        const showNickNameDialog = ref(false)
        const newNickName = ref('')
        // 修改昵称
        const handleUpdateNickName = () => {
            if(!newNickName.value) {
                return Toast.fail('请输入新的昵称')
            }
            upNickName({nick_name:newNickName.value}).then(res => {
                if(res.result == 200000) {
                    showNickNameDialog.value = false
                    newNickName.value = ''
                    Toast.success('修改成功')
                    handleGetMemberInfo()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 是否显示修改头像弹框
        const showAvatarDialog = ref(false)
        // 存储上传头像后得到的文件流
        const uploadFile = ref([])
        // 存储上传头像后得到的头像地址
        const avatarUrl = ref('')
        // 上传头像后
        const afterUpload = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'avatar')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    avatarUrl.value = res.data.path
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 修改头像
        const handleUpdateAvatar = () => {
            upAvatar({avatar: avatarUrl.value}).then(res => {
                if(res.result == 200000) {
                    Toast.success('修改成功')
                    showAvatarDialog.value = false
                    uploadFile.value = []
                    handleGetMemberInfo()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 是否显示关于我们弹框
        const showAboutUsDialog = ref(false)
        // 打开关于我们弹框
        const handleGetAbout = () => {
            getAbout().then(res => {
                if(res.result == 200000) {
                    aboutContent.value = res.data.info
                    showAboutUsDialog.value = true
                }
            })
        }
        // 存储内容
        const aboutContent = ref('')
        // 是否显示转账弹框
        const showTransferDialog = ref(false)
        // 关闭修改登录密码弹框
        const handleCloseTransferDialog = () => {
            showTransferDialog.value = false
        }
        // 是否显示修改登录密码弹框
        const showUpdateLoginPasswordDialog = ref(false)
        // 关闭修改登录密码弹框
        const handleCloseUpdateLoginPasswordDialog = () => {
            showUpdateLoginPasswordDialog.value = false
        }
        // 是否显示修改支付密码弹框
        const showUpdatePayPasswordDialog = ref(false)
        // 关闭修改支付密码弹框
        const handleCloseUpdatePayPasswordDialog = () => {
            showUpdatePayPasswordDialog.value = false
        }
        // 是否显示资金记录弹框
        const showFundRecordDialog = ref(false)
        // 关闭修改支付密码弹框
        const handleCloseFundRecordDialog = () => {
            showFundRecordDialog.value = false
        }
        // 是否显示个人报表弹框
        const showPersonalReportDialog = ref(false)
        // 关闭个人报表弹框
        const handleClosePersonalReportDialog = () => {
            showPersonalReportDialog.value = false
        }
        // 是否显示代理佣金弹框
        const showAgencyCommissionDialog = ref(false)
        // 关闭代理佣金弹框
        const handleCloseAgencyCommissionDialog = () => {
            showAgencyCommissionDialog.value = false
        }
        // 帮助中心-默认tab索引值
        const curHelpIndex = ref(1)
        return {
            memberInfo,
            openCertificationUrl,
            copyAddress,
            handleDoLogout,
            themeImgs,
            isShowDiv,
            showPersonalCenterDialog,
            curTabIndex,
            showNickNameDialog,
            newNickName,
            handleUpdateNickName,
            showAvatarDialog,
            afterUpload,
            uploadFile,
            handleUpdateAvatar,
            showAboutUsDialog,
            handleGetAbout,
            aboutContent,
            showTransferDialog,
            handleCloseTransferDialog,
            showUpdateLoginPasswordDialog,
            handleCloseUpdateLoginPasswordDialog,
            showUpdatePayPasswordDialog,
            handleCloseUpdatePayPasswordDialog,
            showFundRecordDialog,
            handleCloseFundRecordDialog,
            showPersonalReportDialog,
            handleClosePersonalReportDialog,
            showAgencyCommissionDialog,
            handleCloseAgencyCommissionDialog,
            curHelpIndex
        }
    }
}
</script>

<style lang="less" scoped>
.my-wrap {
    :deep(.personalCenter-dialog) {
        width: 508px;
        height: 323px;
        top: 50%;
        background: url('../../assets/images/my/my-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .my-main-wrap {
            .top-tab-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 48px;
                .top-tab-box {
                    display: flex;
                    align-items: center;
                    width: 240px;
                    height: 30px;
                    margin-top: 12px;
                    background: url('../../assets/images/my/default-tab-bg.png') no-repeat;
                    background-size: 100% 100%;
                    .tab-item {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        flex: 1;
                        span {
                            background: linear-gradient(180deg, rgba(253, 254, 255, 0.8) 0%, rgba(193, 224, 255, 0.8) 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                            font-size: 15px;
                        }
                        &.active {
                            flex: 0 0 114px;
                            height: 100%;
                            background: url('../../assets/images/my/active-tab-bg.png') no-repeat;
                            background-size: 100% 100%;
                            span {
                                background: linear-gradient(180deg, #FDFEFF 0%, #C1E0FF 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                text-fill-color: transparent;
                            }
                        }
                    }
                }
            }
            .my-content-wrap {
                height: 273px;
                .content-top-wrap {
                    display: flex;
                    justify-content: space-between;
                    padding: 12px 33px 0;
                    margin: 0 16px;
                    .left-wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        .my-avatar {
                            width: 64px;
                            height: 64px;
                            margin-bottom: 14px;
                        }
                        .btn-box {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            width: 160px;
                            .btn {
                                width: 76px;
                                height: 26px;
                                background: url('../../assets/images/common/small-btn-bg.png') no-repeat;
                                background-size: 100% 100%;
                                line-height: 26px;
                                text-align: center;
                                font-size: 12px;
                                font-weight: 500;
                                text-shadow: 0px 0.809625px 0.809625px #305A9D;
                            }
                        }
                    }
                    .right-wrap {
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-around;
                        .info-item-box {
                            display: flex;
                            align-items: center;
                            img {
                                width: 18px;
                                height: 18px;
                            }
                            .info {
                                width: 130px;
                                margin: 0 10px;
                                overflow: hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                        }
                    }
                }
                .content-item-wrap {
                    height: 135px;
                    margin: 14px 16px 0;
                    padding: 18px 18px 0;
                    background: #0B1D4E;
                    border-radius: 8px;
                    overflow-y: auto;
                    .van-cell {
                        padding: 10px;
                        background: none;
                        &::after {
                            border-color: rgba(193, 224, 255, 0.8);
                        }
                    }
                    .van-image {
                        width: 20px;
                        height: 20px;
                    }
                    .van-cell__title {
                        margin-left: 24px;
                    }
                    .van-cell__value {
                        flex: 0 0 59%;
                        text-align: center;
                    }
                    .logout-btn-box {
                        padding: 20px 23px;
                        border-radius: 8px;
                        .van-button {
                            width: 216px;
                            height: 31px;
                            margin: auto;
                            background: url('../../assets/images/common/btn-bg.png') no-repeat;
                            background-size: 100% 100%;
                            border-radius: 8px;
                        }
                    }
                }
                &.help-center {
                    display: flex;
                    height: 296px;
                    .left-wrap {
                        width: 118px;
                        padding-top: 5px;
                        height: 100%;
                        background: linear-gradient(180deg, #023962 -5.65%, #121E53 100%);
                        overflow-y: auto;
                        .left-item-box {
                            position: relative;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 100%;
                            height: 46px;
                            background: linear-gradient(90.23deg, #306EAE 0.24%, rgba(48, 110, 174, 0) 81.02%);
                            &::after {
                                content:"";
                                position: absolute;
                                bottom: 0;
                                left: 19px;
                                width: 80px;
                                height: 1px;
                                background:  linear-gradient(90deg, rgba(57, 137, 221, 0) 1.26%, #4B8DD2 54.17%, rgba(57, 137, 221, 0) 104.38%);
                            }
                            span {
                                padding: 0 10px;
                                background: linear-gradient(180deg, #FFFFFF 0%, #ACD3EF 100%);
                                -webkit-background-clip: text;
                                -webkit-text-fill-color: transparent;
                                background-clip: text;
                                text-fill-color: transparent;
                                font-family: 'FZZhengHeiS-B-GB';
                                font-size: 15px;
                                line-height: 15px;
                                overflow:hidden;
                                white-space: nowrap;
                                text-overflow: ellipsis;
                            }
                            &.active {
                                background: url('../../assets/images/common/active-bar-bg.png') no-repeat;
                                background-size: 100% 100%;
                                span {
                                    background: linear-gradient(180deg, #FFFFE7 0%, #FEFEA0 100%);
                                    -webkit-background-clip: text;
                                    -webkit-text-fill-color: transparent;
                                    background-clip: text;
                                    text-fill-color: transparent;
                                }
                            }
                        }
                    }
                    .right-wrap {
                        flex: 1;
                        height: 100%;
                        overflow-y: auto;
                        img {
                            width: 100%;
                        }
                    }
                }
            }
        }
    }
    :deep(.nickname-dialog) {
        width: 322px;
        height: 212px;
        top: 50%;
        padding-top: 40px;
        background: url('../../assets/images/my/update-nickname-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .input-box {
            padding: 40px 45px;
            .van-field {
                background: #293966;
                border-radius: 4px;
            }
        }
        .btn-box {
            padding: 14px 0;
            .van-button {
                width: 216px;
                height: 31px;
                margin: auto;
                background: url('../../assets/images/common/btn-bg.png') no-repeat;
                background-size: 100% 100%;
                border-radius: 8px;
            }
        }
    }
    :deep(.avatar-dialog) {
        width: 322px;
        height: 212px;
        top: 50%;
        padding-top: 40px;
        background: url('../../assets/images/my/update-avatar-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .uploader-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 115px;
            background: #0B1D4E;
            border-radius: 12.5614px;
            .van-uploader__upload {
                margin: 0;
            }
        }
        .btn-box {
            padding: 14px 0;
            .van-button {
                width: 216px;
                height: 31px;
                margin: auto;
                background: url('../../assets/images/common/btn-bg.png') no-repeat;
                background-size: 100% 100%;
                border-radius: 8px;
            }
        }
    }
    :deep(.aboutus-dialog) {
        width: 446px;
        height: 302px;
        top: 50%;
        padding-top: 55px;
        background: url('../../assets/images/my/aboutus-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .aboutUs-main-box {
            height: 244px;
            padding: 12px;
            overflow-y: auto;
            font-size: 15px;
            line-height: 180%;
            letter-spacing: 0.5px;
            text-indent: 2em;
            word-wrap: break-word;
            word-break: break-all;
        }
    }
}
</style>
