<template>
    <div class="order-wrap">
        <van-dialog class="fund-dialog" v-model:show="showFundRecordDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseFundRecordDialog"></div>
            <div class="first-tab-wrap" @click="showTypeActions=true">{{curTypeName}}<van-image :src="require('../../assets/images/my/solid_down_icon.png')" /></div>
            <div class="order-main-wrap">
                <div class="date-wrap">
                    <div class="date-box" @click="showDateCalendar=true">
                        <div class="date-item-box">
                            <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                            <span class="date">{{startDate}}</span>
                            <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                        </div>
                        <p>至</p>
                        <div class="date-item-box">
                            <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                            <span class="date">{{endDate}}</span>
                            <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                        </div>
                    </div>
                </div>
                <div class="order-content-wrap" v-if="rebateList.length>0">
                    <van-list
                        v-model:loading="loadingMore"
                        :finished="finishedMore"
                        finished-text="没有更多了"
                        @load="onLoadMore"
                        :immediate-check="false"
                    >
                        <div class="order-item-box" v-for="(item,index) in rebateList" :key="index">
                            <div class="item-box">
                                <span>{{item.type}}</span>
                                <span class="status">{{item.status}}</span>
                            </div>
                            <div class="item-box">
                                <span class="money">{{item.currency_money}}</span>
                                <span class="date">{{item.created_at}}</span>
                            </div>
                        </div>
                    </van-list>
                </div>
                <van-empty
                    v-else
                    class="custom-image"
                    :image="require('../../assets/images/common/empty-img.png')"
                    description="暂无数据～"
                />
            </div>
        </van-dialog>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
        <!-- 查询类型 -->
        <van-action-sheet
            v-model:show="showTypeActions"
            :actions="typeActions"
            cancel-text="取消"
            close-on-click-action
            @cancel="showTypeActions=false"
            @select="handleSelectActions"
        />
    </div>
</template>

<script>
import { reactive, ref, onMounted,computed } from 'vue'
import {getFundRecord} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import { Toast } from 'vant'
import { useStore } from "vuex";

export default {
    props: ['handleCloseFundRecordDialog'],
    setup () {
        const store = useStore()
        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            apiParams.page = 1
            apiParams.pageSize = 20
            rebateList.value = []
            finishedMore.value = false
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            handleGetFundRecord()
        }
        // 当前交易类型type
        const curTypeName = ref('全部')
        // 是否显示交易类型动作面板
        const showTypeActions = ref(false)
        // 交易类型actions
        const typeActions = [
            { name: '全部',type:"" },
            { name: '人工加款',type:1 },
            { name: '人工扣款',type:2 },
            { name: '买币-成功',type:4 },
            { name: '上架货币',type:3 },
            { name: '上架货币强制退回',type:13 },
            { name: '卖币成功',type:14 },
            { name: 'api充值',type:9 },
            { name: 'api提现',type:7 },
            { name: '佣金',type:15 },
            { name: '用户转账',type:17 },
            { name: '用户收款',type:18 },
            { name: '代理转账',type:21 },
            { name: '代理收款',type:22 },
        ]
        // 选择交易类型
        const handleSelectActions = action => {
            apiParams.type = action.type
            apiParams.page = 1
            apiParams.pageSize = 20
            rebateList.value = []
            finishedMore.value = false
            curTypeName.value = action.name
            handleGetFundRecord()
        }
        // 存储返佣列表数据
        const rebateList = ref([])
        // 存储接口默认请求参数
        const apiParams = reactive({
            type: "",
            page: 1,
            pageSize: 20,
            start: `${startDate.value} 00:00:00`,
            end: `${endDate.value} 23:59:59`
        })
        // 获取返佣列表数据
        const handleGetFundRecord = (more) => {
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getFundRecord(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        rebateList.value = res.data.list.list
                    } else {
                        rebateList.value = res.data.list.list
                        loadingMore.value = false
                        if(res.data.list.list.length>=res.data.list.count) {
                            finishedMore.value = true
                        }
                    }
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        onMounted(() => {
            handleGetFundRecord()
        })
        // 上拉加载
        const loadingMore = ref(false);
        const finishedMore = ref(false);
        const onLoadMore = () => {
            handleGetFundRecord(true)
        };
        // 复制
        const { toClipboard } = useClipboard()
        const handleCopy = async(content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示修改资金记录弹框
        const showFundRecordDialog = ref(true)
        return {
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            rebateList,
            loadingMore,
            finishedMore,
            onLoadMore,
            handleCopy,
            apiParams,
            curTypeName,
            showTypeActions,
            typeActions,
            handleSelectActions,
            themeImgs,
            showFundRecordDialog
        }
    }
}
</script>

<style lang="less" scoped>
.order-wrap {
    :deep(.fund-dialog) {
        width: 445px;
        height: 320px;
        top: 50%;
        padding-top: 60px;
        background: url('../../assets/images/my/fund-record-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .first-tab-wrap {
            position: absolute;
            width: 100%;
            top: 27px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 36px;
            font-size: 15px;
            color: #FFD65A;
            font-weight: 500;
            .van-image {
                width: 16px;
                height: 16px;
            }
        }
        .order-main-wrap {
            height: 257px;
            .date-wrap {
                padding: 12px;
                .date-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 36px;
                    padding: 0 20px;
                    background: #0B1E4E;
                    border-radius: 17px;
                    .date-item-box {
                        display: flex;
                        align-items: center;
                        .van-image {
                            width: 16px;
                            height: 16px;
                        }
                        p {
                            font-size: 16px;
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                        .date {
                            padding: 0 10px;
                            font-size: 16px;
                            font-family: 'DINOffcPro';
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                    }
                }
            }
            .order-content-wrap {
                height: 197px;
                padding: 0 34px 10px;
                overflow-y: scroll;
                .order-item-box {
                    display: block;
                    padding: 8px 0;
                    border-bottom: 1px solid #eee;
                    &:last-child {
                        border-bottom: none;
                    }
                    .item-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 30px;
                        span {
                            &.date {
                                opacity: 0.5;
                            }
                        }
                        .status {
                            font-size: 16px;
                        }
                        .money {
                            font-family: 'DIN Offc Pro';
                            font-size: 20px;
                            font-weight: 500;
                        }
                    }
                }
            }
        }
    }
}
</style>