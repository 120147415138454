<template>
    <div class="register-wrap">
        <router-link to="/login" class="login-btn"></router-link>
        <div class="register-out-box">
            <div class="register-box" v-if="showUsernameReg">
                <van-field
                    v-model="regParams.username"
                    :formatter="formatter"
                    clearable
                    center
                    autocomplete="off"
                    :left-icon="require('@/assets/images/common/user-icon.png')"
                    placeholder="输入用户名"
                />
                <van-field
                    v-model="regParams.password"
                    :formatter="formatter"
                    type="password"
                    clearable
                    center
                    autocomplete="off"
                    placeholder="输入密码"
                    :left-icon="require('@/assets/images/common/key-icon.png')"
                />
                <van-field
                    v-model="regParams.confirm_password"
                    :formatter="formatter"
                    type="password"
                    clearable
                    center
                    autocomplete="off"
                    placeholder="再次输入密码"
                    :left-icon="require('@/assets/images/common/key-icon.png')"
                />
                <van-field
                    v-model="regParams.pay_password"
                    maxlength="6"
                    type="password"
                    :formatter="formatter"
                    clearable
                    center
                    autocomplete="off"
                    placeholder="设置6位纯数字支付密码"
                    :left-icon="require('@/assets/images/common/lock-icon.png')"
                />
                <van-field
                    v-if="codeType==1"
                    v-model="regParams.code"
                    maxlength="4"
                    :formatter="formatter"
                    center
                    autocomplete="off"
                    placeholder="请输入验证码"
                >
                    <template #extra>
                        <van-image class="code-img" :src="graphicCodeImg" @click="handleGetGraphicCode" />
                    </template>
                </van-field>
                <Geet ref="regGeetRef" v-show="codeType==3" @handleUpdateGeetStatus="handleUpdateGeetStatus" @handleGetGeetSession="handleGetGeetSession" />
            </div>
            <div v-if="!showUsernameReg">
                <div class="register-box" v-if="!showRegPage">
                    <van-field
                        v-model="SMSCodeParams.username"
                        :formatter="formatter"
                        clearable
                        center
                        autocomplete="off"
                        placeholder="输入手机号码/邮箱"
                        :left-icon="themeImgs.loginRegPhoneIcon"
                    />
                    <van-field
                        v-if="codeType==1"
                        v-model="SMSCodeParams.code"
                        maxlength="4"
                        :formatter="formatter"
                        center
                        autocomplete="off"
                        placeholder="请输入验证码"
                    >
                        <template #extra>
                            <van-image class="code-img" :src="graphicCodeImg" @click="handleGetGraphicCode" />
                        </template>
                    </van-field>
                    <Geet ref="regGeetRef" v-show="codeType==3" @handleUpdateGeetStatus="handleUpdateGeetStatus" @handleGetGeetSession="handleGetGeetSession" />
                </div>
                <div class="register-box" v-if="showRegPage">
                    <van-field
                        v-model="regParams.username"
                        center
                        readonly
                        autocomplete="off"
                        :left-icon="themeImgs.loginRegPhoneIcon"
                    />
                    <van-field
                        v-model="regParams.code"
                        type="number"
                        maxlength="6"
                        :formatter="formatter"
                        clearable
                        center
                        autocomplete="off"
                        placeholder="输入短信/邮箱验证码"
                        :left-icon="themeImgs.loginRegKeyIcon"
                    />
                    <van-field
                        v-model="regParams.password"
                        :formatter="formatter"
                        type="password"
                        clearable
                        center
                        autocomplete="off"
                        placeholder="输入密码"
                        :left-icon="themeImgs.loginRegKeyIcon"
                    />
                    <van-field
                        v-model="regParams.confirm_password"
                        :formatter="formatter"
                        type="password"
                        clearable
                        center
                        autocomplete="off"
                        placeholder="再次输入密码"
                        :left-icon="themeImgs.loginRegKeyIcon"
                    />
                    <van-field
                        v-model="regParams.pay_password"
                        maxlength="6"
                        type="password"
                        :formatter="formatter"
                        clearable
                        center
                        autocomplete="off"
                        placeholder="设置6位纯数字支付密码"
                        :left-icon="themeImgs.loginRegLockIcon"
                    />
                </div>
            </div>
            <div class="bottom-box" v-if="showUsernameReg">
                <div class="register-btn-box" @click="handleRegister">注册</div>
            </div>
            <div class="bottom-box" v-if="!showUsernameReg">
                <div class="register-btn-box" @click="handleClickSMSCodeBtn" v-if="!showRegPage">获得短信/邮箱验证码</div>
                <div class="register-btn-box" @click="handleRegister" v-if="showRegPage">注册</div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Icon, Toast } from "vant";
import { getVerification, getGraphicCode, doRegister,getCodeType, getLoginRegSys } from "@/api/api";
import wsConnection from "@/utils/websocket"
import Geet from "@/components/Geet"

export default defineComponent({
    components: {
        [Icon.name]: Icon,
        Geet
    },
    setup() {
        const $router = useRouter();
        const store = useStore();
        const regGeetRef = ref()
        // 默认行为验证码状态
        const geetStatus = ref(1)
        // 改变行为验证码状态
        const handleUpdateGeetStatus = val => {
            geetStatus.value = val
        }
        // 存储验证码方式
        const codeType = ref('')
        // 获取验证码方式
        const handleGetCodeType = () => {
            getCodeType().then(res => {
                if(res.result == 200000) {
                    codeType.value = res.data.type
                    if(res.data.type==1) {
                        handleGetGraphicCode()
                    } else {
                        regGeetRef.value.init(res.data)
                        SMSCodeParams.session = res.data.session_id
                        regParams.session = res.data.session_id
                    }
                }
            })
        }
        // 获取极验证session
        const handleGetGeetSession = () => {
            handleGetCodeType()
        }
        // 存储图形验证码
        const graphicCodeImg = ref('')
        // 获取图形验证码
        const handleGetGraphicCode = () => {
            getGraphicCode().then(res => {
                if(res.result == 200000) {
                    graphicCodeImg.value = res.data.captcha
                    SMSCodeParams.session = res.data.session
                    regParams.session = res.data.session
                }
            })
        }
        // 获取登录验证配置
        const handleGetLoginRegSys = () => {
            getLoginRegSys().then(res => {
                if(res.result == 200000) {
                    if (res.data.info.register == 1) {
                        showUsernameReg.value = false
                    } else {
                        showUsernameReg.value = true
                    }
                    handleGetCodeType()
                }
            })
        }
        onBeforeMount(()=> {
            // handleGetCodeType()
            handleGetLoginRegSys()
        })
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        const inputType = ref("password");
        const showEye = ref(true);
        const SMSCodeParams = reactive({
            username: "", // 用户名
            code: "", // 图形验证码密码
            session: ""
        });
        const regParams = reactive({
            username: "", // 用户名
            password: "", // 密码
            confirm_password: "", // 确认密码
            pay_password: "", // 支付密码
            code: "", // 短信/邮箱验证码
            session: '',
            is_captcha: 1
        });
        // 显示密码
        const changePassWordType = () => {
            showEye.value = !showEye.value;
            if (showEye.value) {
                inputType.value = "password";
            } else {
                inputType.value = "text";
            }
        };
        // 点击按钮获取短信验证码
        const handleClickSMSCodeBtn = () => {
            if (!SMSCodeParams.username) {
                return Toast.fail("请输入手机号码/邮箱");
            } else if (codeType.value==1 && !SMSCodeParams.code) {
                return Toast.fail("请输入验证码");
            } else if (codeType.value==3 && geetStatus.value!=4) {
                return Toast.fail("请点击进行验证");
            } else {
                handleGetSMSCode()
            }
        }
        // 获取短信验证码
        const handleGetSMSCode = () => {
            Toast.loading({
                message: '发送中...',
                forbidClick: true,
            });
            getVerification(SMSCodeParams).then(res => {
                Toast.clear()
                if(res.result == 200000) {
                    Toast.success("发送成功");
                    showRegPage.value = true
                    regParams.username = SMSCodeParams.username
                } else {
                    if(codeType.value==3) {
                        regGeetRef.value.init()
                    } else {
                        handleGetGraphicCode()
                    }
                    Toast.fail(res.msg);
                }
            })
        }
        // 显示第二注册页
        const showRegPage = ref(false)
        // 点击注册
        const handleRegister = () => {
            if (!regParams.username) {
                return Toast.fail("请输入用户名");
            } else if (!regParams.code && !showUsernameReg.value) {
                return Toast.fail("请输入短信/邮箱验证码");
            } else if (!regParams.password) {
                return Toast.fail("请输入密码");
            } else if (!regParams.confirm_password) {
                return Toast.fail("请再次确认密码");
            } else if (regParams.password != regParams.confirm_password) {
                return Toast.fail("两次密码不一致");
            } else if (!regParams.pay_password) {
                return Toast.fail("请设置支付密码");
            }  else if (codeType.value==1 && !regParams.code && showUsernameReg.value) {
                return Toast.fail("请输入验证码");
            } else if (codeType.value==3 && geetStatus.value!=4 && showUsernameReg.value) {
                return Toast.fail("请点击进行验证");
            } else {
                doRegister(regParams).then((res) => {
                    if (res.result == 200000) {
                        Toast.success("注册成功");
                        store.dispatch("saveLoginInfosActions", res.data);
                        $router.push("/home");
                        wsConnection.initWebSocket()
                    } else {
                        if (showUsernameReg.value) {
                            if(codeType.value==3) {
                                handleGetCodeType()
                            } else {
                                handleGetGraphicCode()
                            }
                        }
                        Toast.fail(res.msg)
                    }
                });
            }
        }
        // 是否显示用户名或手机号/邮箱注册结构
        const showUsernameReg = ref(true)
        // 点击切换注册类型
        const handleChangeRegType = () => {
            showUsernameReg.value = !showUsernameReg.value
            regParams.username = ""
            regParams.password = ""
            regParams.confirm_password = ""
            regParams.pay_password = ""
            regParams.code = ""
            regParams.session = ""
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            graphicCodeImg,
            handleGetGraphicCode,
            formatter,
            inputType,
            showEye,
            SMSCodeParams,
            regParams,
            changePassWordType,
            showRegPage,
            handleClickSMSCodeBtn,
            handleRegister,
            codeType,
            geetStatus,
            handleUpdateGeetStatus,
            regGeetRef,
            handleGetGeetSession,
            showUsernameReg,
            handleChangeRegType,
            themeImgs
        };
    },
});
</script>

<style lang="less" scoped>
.register-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 441px;
    height: 351px;
    padding-top: 78px;
    background: url('../assets/images/reg/reg-bg.png') no-repeat center top;
    background-size: 100% 100%;
    .login-btn {
        position: absolute;
        width: 120px;
        height: 30px;
        top: 37px;
        left: 110px;
    }
    .register-out-box {
        width: 100%;
        height: 100%;
        padding: 0 66px 17px;
        overflow-y: auto;
        .register-box {
            :deep(.van-cell) {
                margin-top: 17px;
                background: #00103C;
                border-radius: 4px;
                .van-field__left-icon {
                    .van-icon {
                        width: 24px;
                        height: 24px;
                    }
                }
                .van-field__control {
                    padding-left: 10px;
                }
                input::-webkit-input-placeholder {
                    color: rgba(255, 255, 255, 0.6);
                }
                .van-icon-clear {
                    font-size: 18px;
                }
                .code-img {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 100px;
                    height: 34px;
                }
            }
        }
        .register-btn-box {
            width: 216px;
            height: 31px;
            margin: 16px auto 0;
            line-height: 31px;
            background: url('../assets/images/common/btn-bg.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            text-shadow: 0px 1px 1px #305A9D;
            color: #fff;
        }
    }
}
</style>