<template>
    <div class="order-wrap">
        <van-dialog class="order-dialog" v-model:show="showOrderDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseOrderDialog"></div>
            <div class="first-tab-wrap">
                <div class="first-tab-box">
                    <van-badge :content="UntreatedBuyNum"><div class="tab-item" :class="{'active':firstTabIndex==0}" @click="changeFirstTab(0)">买单</div></van-badge>
                    <van-badge :content="UntreatedSellNum"><div class="tab-item" :class="{'active':firstTabIndex==1}" @click="changeFirstTab(1)">卖单</div></van-badge>
                    <van-badge><div class="tab-item" :class="{'active':firstTabIndex==2}" @click="changeFirstTab(2)">挂单</div></van-badge>
                    <van-badge><div class="tab-item" :class="{'active':firstTabIndex==3}" @click="changeFirstTab(3)">API订单</div></van-badge>
                </div>
            </div>
            <div class="order-main-wrap">
                <div class="second-tab-wrap" v-if="firstTabIndex<2">
                    <div class="tab-item" :class="{'active':secondTabIndex==4}" @click="changeSecondTab(4)"><span>全部</span></div>
                    <div class="tab-item" :class="{'active':secondTabIndex==1}" @click="changeSecondTab(1)"><span>进行中</span></div>
                    <div class="tab-item" :class="{'active':secondTabIndex==2}" @click="changeSecondTab(2)"><span>已完成</span></div>
                    <div class="tab-item" :class="{'active':secondTabIndex==3}" @click="changeSecondTab(3)"><span>已取消</span></div>
                </div>
                <div class="second-tab-wrap" v-if="firstTabIndex==2">
                    <div class="tab-item" :class="{'active':threeTabIndex==''}" @click="changeThreeTab('')"><span>全部</span></div>
                    <div class="tab-item" :class="{'active':threeTabIndex==1}" @click="changeThreeTab(1)"><span>出售中</span></div>
                    <div class="tab-item" :class="{'active':threeTabIndex==5}" @click="changeThreeTab(5)"><span>交易中</span></div>
                    <div class="tab-item" :class="{'active':threeTabIndex==3}" @click="changeThreeTab(3)"><span>已完成</span></div>
                    <div class="tab-item" :class="{'active':threeTabIndex==2}" @click="changeThreeTab(2)"><span>已取消</span></div>
                </div>
                <div class="second-tab-wrap" v-if="firstTabIndex==3">
                    <div class="tab-item" :class="{'active':fourTabIndex==1}" @click="changeFourTab(1)"><span>充值</span></div>
                    <div class="tab-item" :class="{'active':fourTabIndex==2}" @click="changeFourTab(2)"><span>提现</span></div>
                    <div class="tab-item" :class="{'active':fourTabIndex==3}" @click="changeFourTab(3)"><span>加款</span></div>
                    <div class="tab-item" :class="{'active':fourTabIndex==4}" @click="changeFourTab(4)"><span>扣款</span></div>
                </div>
                <div class="order-main-box">
                    <div class="date-wrap">
                        <div class="date-box" @click="showDateCalendar=true">
                            <div class="date-item-box">
                                <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                                <span class="date">{{startDate}}</span>
                                <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                            </div>
                            <p>至</p>
                            <div class="date-item-box">
                                <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                                <span class="date">{{endDate}}</span>
                                <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                            </div>
                        </div>
                        <van-field v-if="fourTabIndex<3" v-model="apiParams.order_sn" autocomplete="off" clearable placeholder="请输入搜索的订单编号">
                            <template #button>
                                <van-button size="small" @click="handleSearch">搜索</van-button>
                            </template>
                        </van-field>
                    </div>
                    <div class="order-content-wrap" v-if="orderList.length>0">
                        <van-list
                            v-if="firstTabIndex<2"
                            v-model:loading="loadingMore"
                            :finished="finishedMore"
                            finished-text="没有更多了"
                            @load="onLoadMore"
                            :immediate-check="false"
                        >
                            <div @click="handleOpenOrderDetails(firstTabIndex==0?'buyer':'seller', item.order_sn)" class="order-item-box" v-for="(item,index) in orderList" :key="index">
                                <div class="ordersn-box">
                                    <span>订单编号：{{item.order_sn}}</span>
                                    <van-image :src="require('../../assets/images/common/copy-icon.png')" @click.prevent="handleCopy(item.order_sn)" />
                                </div>
                                <div class="ordersn-box">
                                    <span>挂单编号：{{item.order}}</span>
                                    <van-image :src="require('../../assets/images/common/copy-icon.png')" @click.prevent="handleCopy(item.order)" />
                                </div>
                                <div class="item-box">
                                    <div class="left-box">
                                        <span class="amount">{{item.currency}}</span>
                                        <span class="unit">{{store.state.publicConfig.title}}</span>
                                    </div>
                                    <div class="right-box">
                                        <span class="status">{{orderStatusFilter(Number(item.status))}}</span>
                                        <span class="date">{{item.created_at}}</span>
                                    </div>
                                </div>
                            </div>
                        </van-list>
                        <van-list
                            v-if="firstTabIndex==2"
                            v-model:loading="loadingMore"
                            :finished="finishedMore"
                            finished-text="没有更多了"
                            @load="onLoadMore"
                            :immediate-check="false"
                        >
                            <div class="order-item-box" v-for="(item,index) in orderList" :key="index">
                                <div class="ordersn-box">
                                    <span>订单编号：{{item.order_sn}}</span>
                                    <van-image :src="require('../../assets/images/common/copy-icon.png')" @click="handleCopy(item.order_sn)" />
                                </div>
                                <div class="item-box">
                                    <div class="left-box">
                                        <span class="amount">{{item.currency}}</span>
                                        <span class="unit">{{store.state.publicConfig.title}}</span>
                                    </div>
                                    <div class="right-box">
                                        <div class="status-btn-box">
                                            <span class="status">{{hangOrderStatusFilter(Number(item.status))}}</span>
                                            <van-button v-if="item.status==1" class="cancle-btn" type="danger" :disabled="btnClock" round @click="handleCancelSell(item.id)">下架</van-button>
                                        </div>
                                        <span class="date">{{item.created_at}}</span>
                                    </div>
                                </div>
                                <div class="ordersn-box">
                                    <span>总出售：{{item.count_currency}}</span>
                                    <span>已完成：{{item.traded_currency}}</span>
                                    <span>交易中：{{item.freeze_currency}}</span>
                                </div>
                            </div>
                        </van-list>
                        <van-list
                            v-if="firstTabIndex==3"
                            v-model:loading="loadingMore"
                            :finished="finishedMore"
                            finished-text="没有更多了"
                            @load="onLoadMore"
                            :immediate-check="false"
                        >
                            <div class="order-item-box" v-for="(item,index) in orderList" :key="index">
                                <div class="ordersn-box" v-if="fourTabIndex<3">
                                    <span>订单编号：{{item.order}}</span>
                                    <van-image :src="require('../../assets/images/common/copy-icon.png')" @click="handleCopy(item.order_sn)" />
                                </div>
                                <div class="item-box">
                                    <div class="left-box">
                                        <span class="amount">{{fourTabIndex<3 ? item.amount : item.currency}}</span>
                                        <span class="unit">{{store.state.publicConfig.title}}</span>
                                    </div>
                                    <div class="right-box">
                                        <span class="status" v-if="fourTabIndex<3">{{apiOrderStatusFilter(Number(item.status))}}</span>
                                        <span class="status" v-if="fourTabIndex==3">人工加款</span>
                                        <span class="status" v-if="fourTabIndex==4">人工扣款</span>
                                        <span class="date">{{item.created_at}}</span>
                                    </div>
                                </div>
                            </div>
                        </van-list>
                    </div>
                    <van-empty
                        v-else
                        class="custom-image"
                        :image="require('../../assets/images/common/empty-img.png')"
                        description="暂无数据～"
                    />
                </div>
            </div>
        </van-dialog>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
        <van-dialog class="take-down-dialog" v-model:show="showTakeDownDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showTakeDownDialog=false"></div>
            <p>确认将该笔订单进行下架吗？</p>
            <div class="comfirm-btn-box">
                <van-button :disabled="btnClock" @click="handleComfirmCancelSell">确认</van-button>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { reactive, ref, onMounted,computed,watch } from 'vue'
import {getBuyOrderLists,getSellOrderLists,getSellOrderDealLists,getApiOrderLists,getOrderRemind,doCancelSellOrder} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import { Toast,Dialog } from 'vant'
import { onBeforeRouteLeave } from 'vue-router'
import { useStore } from 'vuex'

export default {
    props: ['handleCloseOrderDialog', 'handleOpenOrderDetails'],
    setup (props) {
        const store = useStore()
        // 存储未处理买单的数量
        const UntreatedBuyNum = ref()
        // 存储未处理卖单的数量
        const UntreatedSellNum = ref()
        // 获取订单状态提醒
        const handleGetOrderRemind = () => {
            getOrderRemind().then(res => {
                if(res.result == 200000) {
                    if(res.data.buy_count>0) {
                        UntreatedBuyNum.value = res.data.buy_count
                    } else {
                        UntreatedBuyNum.value = ""
                    }
                    if(res.data.sell_count>0) {
                        UntreatedSellNum.value = res.data.sell_count
                    } else {
                        UntreatedSellNum.value = ""
                    }
                }
            })
        }
        // 计算属性实现视图更新--新订单提醒
        const orderRemindObj = computed(() => store.state.orderRemind)
        watch(orderRemindObj, (newValue) =>{
            handleGetOrderRemind()
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 第一层tab索引值
        const firstTabIndex = ref(localStorage.getItem('firstTabIndex')?localStorage.getItem('firstTabIndex'):0)
        // 第二层tab索引值
        const secondTabIndex = ref(4)
        // 第三层tab索引值
        const threeTabIndex = ref('')
        // 第四层tab索引值
        const fourTabIndex = ref(1)
        // 点击第一层tab
        const changeFirstTab = id => {
            firstTabIndex.value = id
            secondTabIndex.value = 4
            threeTabIndex.value = ''
            fourTabIndex.value = 1
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            if(id==0) {
                handleGetBuyOrderList()
            } else if(id==1) {
                handleGetSellOrderDealList()
            } else if(id==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 点击第二层tab
        const changeSecondTab = id => {
            secondTabIndex.value = id
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            }
        }
        // 点击第三层tab
        const changeThreeTab = id => {
            threeTabIndex.value = id
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            handleGetSellOrderList()
        }
        // 点击第四层tab
        const changeFourTab = id => {
            fourTabIndex.value = id
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            orderList.value = []
            finishedMore.value = false
            handleGetApiOrderList()
        }
        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            orderList.value = []
            apiParams.pageSize = 20
            finishedMore.value = false
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 存储订单列表数据
        const orderList = ref([])
        // 存储接口默认请求参数
        const apiParams = reactive({
            type: 1,
            order_sn: '',
            page: 1,
            pageSize: 20,
            start: `${startDate.value} 00:00:00`,
            end: `${endDate.value} 23:59:59`
        })
        // 获取买家订单列表数据
        const handleGetBuyOrderList = (more) => {
            apiParams.type = secondTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getBuyOrderLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
                }
            })
        }
        // 获取卖家卖单列表数据
        const handleGetSellOrderDealList = (more) => {
            apiParams.type = secondTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getSellOrderDealLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
                }
            })
        }
        // 获取卖家挂单列表数据
        const handleGetSellOrderList = (more) => {
            apiParams.status = threeTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.type
            if(more) {
                apiParams.pageSize+=20
            }
            getSellOrderLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list
                    } else {
                        orderList.value = res.data.list
                        loadingMore.value = false
                        if(res.data.list.length>=res.data.count) {
                            finishedMore.value = true
                        }
                    }
                }
            })
        }
        // 获取API订单列表数据
        const handleGetApiOrderList = (more) => {
            apiParams.type = fourTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            delete apiParams.status
            if(more) {
                apiParams.pageSize+=20
            }
            getApiOrderLists(apiParams).then(res => {
                if(res.result == 200000) {
                    if(!more) {
                        orderList.value = res.data.list.list
                    } else {
                        orderList.value = res.data.list.list
                        loadingMore.value = false
                        if(res.data.list.list.length>=res.data.list.count) {
                            finishedMore.value = true
                        }
                    }
                }
            })
        }
        onMounted(() => {
            handleGetOrderRemind()
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        })
        // 订单状态过滤器
        const orderStatusFilter = (status) => {
            if(status<5 || status==10) {
                return '进行中'
            }
            if(status==5 || status==9) {
                return '已完成'
            }
            if(status==6 || status==7 || status==8) {
                return '已取消'
            }
            if (status == 12) {
                return '人工退回'
            }
            if (status == 13) {
                return '人工打币'
            }
          if (status == 14) {
            return '系统冻结'
          }
        }
        // 挂单状态过滤器
        const hangOrderStatusFilter = (status) => {
            if(status==1) {
                return '出售中'
            }
            if(status==2) {
                return '已取消'
            }
            if(status==3) {
                return '已完成'
            }
            if(status==4) {
                return '已冻结'
            }
            if(status==5) {
                return '交易中'
            }
        }
        // api订单状态过滤器
        const apiOrderStatusFilter = (status) => {
            if(status==1) {
                return '待支付'
            }
            if(status==2) {
                return '成功'
            }
            if(status==3) {
                return '失败'
            }
        }
        // 上拉加载
        const loadingMore = ref(false);
        const finishedMore = ref(false);
        const onLoadMore = () => {
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList(true)
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList(true)
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList(true)
            } else {
                handleGetApiOrderList(true)
            }
        };
        // 复制
        const { toClipboard } = useClipboard()
        const handleCopy = async(content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 搜索
        const handleSearch = () => {
            orderList.value = []
            apiParams.page = 1
            apiParams.pageSize = 20
            finishedMore.value = false
            if(firstTabIndex.value==0) {
                handleGetBuyOrderList()
            } else if(firstTabIndex.value==1) {
                handleGetSellOrderDealList()
            } else if(firstTabIndex.value==2) {
                handleGetSellOrderList()
            } else {
                handleGetApiOrderList()
            }
        }
        // 打开下架挂单弹框
        const handleCancelSell = id => {
            curTakeDownId.value = id
            showTakeDownDialog.value = true
        }
        // 确认下架挂单
        const handleComfirmCancelSell = () => {
            if (btnClock.value) return
            btnClock.value = true
            doCancelSellOrder({id:curTakeDownId.value}).then(res=>{
                btnClock.value = false
                if(res.result==200000) {
                    Toast.success('下架成功')
                    showTakeDownDialog.value = false
                    handleGetSellOrderList()
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        onBeforeRouteLeave((to, from, next) => {
            if(to.path.includes('OrderDetails')) {
                window.localStorage.setItem('firstTabIndex',firstTabIndex.value)
                next()
            } else {
                window.localStorage.removeItem('firstTabIndex')
                next()
            }
        })
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示订单弹框
        const showOrderDialog = ref(true)
        // 显示下架挂单弹框
        const showTakeDownDialog = ref(false)
        // 当前下架挂单的id
        const curTakeDownId = ref('')
        return {
            firstTabIndex,
            secondTabIndex,
            threeTabIndex,
            fourTabIndex,
            changeFirstTab,
            changeSecondTab,
            changeThreeTab,
            changeFourTab,
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            orderList,
            orderStatusFilter,
            hangOrderStatusFilter,
            loadingMore,
            finishedMore,
            onLoadMore,
            handleCopy,
            handleSearch,
            apiParams,
            apiOrderStatusFilter,
            UntreatedBuyNum,
            UntreatedSellNum,
            handleCancelSell,
            btnClock,
            themeImgs,
            store,
            showOrderDialog,
            showTakeDownDialog,
            handleComfirmCancelSell
        }
    }
}
</script>

<style lang="less" scoped>
.order-wrap {
    :deep(.order-dialog) {
        width: 508px;
        height: 333px;
        top: 50%;
        padding-top: 82px;
        background: url('../../assets/images/order/order-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .first-tab-wrap {
            position: absolute;
            width: 100%;
            top: 31px;
            display: flex;
            align-items: center;
            justify-content: center;
            height: 60px;
            .first-tab-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 30px;
                background: url('../../assets/images/common/default-tab-bg.png') no-repeat;
                background-size: 100% 100%;
                .van-badge__wrapper {
                    width: 80px;
                    height: 30px;
                    line-height: 30px;
                    text-align: center;
                    :deep(.van-badge) {
                        z-index: 1;
                    }
                    .tab-item.active {
                        background: url('../../assets/images/common/active-tab-bg.png') no-repeat;
                        background-size: 100% 100%;
                    }
                }
            }
        }
        .order-main-wrap {
            display: flex;
            height: 251px;
            padding: 3px 0px 3px 3px;
            .second-tab-wrap {
                width: 115px;
                height: 100%;
                background: linear-gradient(180deg, #023962 -5.65%, #121E53 100%);
                overflow-y: auto;
                .tab-item {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 46px;
                    background: linear-gradient(90.23deg, #306EAE 0.24%, rgba(48, 110, 174, 0) 81.02%);
                    &::after {
                        content:"";
                        position: absolute;
                        bottom: 0;
                        left: 19px;
                        width: 80px;
                        height: 1px;
                        background:  linear-gradient(90deg, rgba(57, 137, 221, 0) 1.26%, #4B8DD2 54.17%, rgba(57, 137, 221, 0) 104.38%);
                    }
                    span {
                        padding: 0 10px;
                        background: linear-gradient(180deg, #FFFFFF 0%, #ACD3EF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        font-family: 'FZZhengHeiS-B-GB';
                        font-size: 15px;
                        line-height: 15px;
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &.active {
                        background: url('../../assets/images/common/active-bar-bg.png') no-repeat;
                        background-size: 100% 100%;
                        span {
                            background: linear-gradient(180deg, #FFFFE7 0%, #FEFEA0 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                    }
                }
            }
            .order-main-box {
                flex: 1;
                height: 250px;
                overflow: auto;
                .date-wrap {
                    padding: 12px;
                    .date-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        width: 100%;
                        height: 36px;
                        padding: 0 20px;
                        background: #0B1E4E;
                        border-radius: 17px;
                        .date-item-box {
                            display: flex;
                            align-items: center;
                            .van-image {
                                width: 16px;
                                height: 16px;
                            }
                            p {
                                font-size: 16px;
                                opacity: 0.9;
                            }
                            .date {
                                padding: 0 10px;
                                font-size: 16px;
                                font-family: 'DINOffcPro';
                                opacity: 0.9;
                            }
                        }
                    }
                    .van-cell {
                        margin-top: 10px;
                        padding-top: 0;
                        padding-bottom: 0;
                        padding-right: 0;
                        background: #0B1E4E;
                        border-radius: 17px;
                        .van-field__button {
                            .van-button__text {
                                color: #0A2B58;
                                font-weight: 500;
                            }
                        }
                    }
                }
                .order-content-wrap {
                    padding: 0 12px;
                    .order-item-box {
                        display: block;
                        padding: 8px 0;
                        border-bottom: 1px solid #979797;
                        &:last-child {
                            border-bottom: none;
                        }
                        .ordersn-box {
                            display: flex;
                            align-items: center;
                            padding: 8px 0;
                            span {
                                font-weight: 500;
                                margin-right: 7px;
                            }
                            .van-image {
                                width: 16px;
                                height: 16px;
                            }
                        }
                        .item-box {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            .left-box {
                                display: flex;
                                align-items: center;
                                span {
                                    line-height: 26px;
                                }
                                .amount {
                                    margin-right: 4px;
                                    font-size: 20px;
                                }
                            }
                            .right-box {
                                display: flex;
                                flex-direction: column;
                                align-items: flex-end;
                                .status-btn-box {
                                    display: flex;
                                    align-items: center;
                                    margin-bottom: 10px;
                                }
                                .status {
                                    margin-right: 10px;
                                    font-size: 16px;
                                }
                                .cancle-btn {
                                    height: 30px;
                                    padding: 0 10px;
                                    font-size: 16px;
                                }
                                .date {
                                    opacity: 0.5;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
:deep(.take-down-dialog) {
    width: 322px;
    height: 211px;
    top: 50%;
    padding-top: 40px;
    background: url('../../assets/images/common/tips-bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 3px;
    .close-btn {
        position: absolute;
        top: 15px;
        right: 0;
        width: 28px;
        height: 28px;
        z-index: 1;
    }
    p {
        height: 115px;
        line-height: 115px;
        text-align: center;
        font-weight: 500;
        font-size: 16px;
    }
    .comfirm-btn-box {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 55px;
        .van-button {
            width: 216px;
            height: 34px;
            margin: auto;
            background: url('../../assets/images/common/btn-bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 8px;
        }
    }
}
</style>