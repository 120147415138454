<template>
    <div class="sellCoin-wrap">
        <van-dialog class="sellcoin-dialog" v-model:show="showSellDialog" :show-confirm-button="false">
            <div class="sellCoin-main-wrap">
                <div class="close-btn" @click="handleCloseSellCoinDialog"></div>
                <div class="sellCoin-main-box">
                    <van-field center type="text" v-model="saleAmountValue" clearable :formatter="formatter" autocomplete="off" :placeholder="`输入出售数量（${store.state.publicConfig.title}）`" @update:model-value="handleUpdateAmount">
                        <template #left-icon>
                            <img src="../assets/images/common/trans-icon.png" alt="" />
                        </template>
                        <template #label>
                            {{labelSaleAmount}}
                        </template>
                    </van-field>
                    <div class="sale-amount">可售余额：{{availableBalance}} {{store.state.publicConfig.title}}</div>
                    <div class="sale-amount" v-show="minSaleAmount">单次出售最小数量：{{minSaleAmount}}</div>
                    <div class="payment-method-wrap">
                        <span class="label">收款方式</span>
                        <div class="payment-method-box">
                            <div class="method-item" :class="{'active':paymentIdArr.includes(type)}" v-for="type in paymentWayList" :key="type" @click="selectPaymentWay(type)">
                                <img :src="require(`../assets/images/common/payment_icon${type}.png`)" alt="" />
                            </div>
                        </div>
                    </div>
                    <div class="sale-method-wrap">
                        <span class="label">出售方式</span>
                        <div class="sale-method-box">
                            <div class="method-item" :class="{'active':sellWayId == 1}" @click="sellWayId=1">拆分</div>
                            <div class="method-item" :class="{'active':sellWayId == 2}" @click="sellWayId=2">不拆分</div>
                        </div>
                    </div>
                    <div class="sale-btn-box">
                        <van-button :disabled="availableBalance == '0.00' || btnClock" block @click="handleSale">出售</van-button>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 支付密码弹出框 -->
        <van-dialog class="pay-password-dialog" v-model:show="showPayPasswordDialog" :show-confirm-button="false">
            <div class="close-btn" @click="onBeforeClose('close')"></div>
            <van-field
                v-model="pay_password"
                type="password"
                label="支付密码"
                placeholder="请输入支付密码"
                maxlength="6"
                clearable
            />
            <van-button class="pay-btn" block @click="onBeforeClose('confirm')">确定</van-button>
        </van-dialog>
    </div>
</template>

<script>
import { onMounted, ref, computed } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import { Toast,Dialog } from 'vant'
import {doSell,getPaymentList,getMemberAmount} from '@/api/api'

export default {
    props:['handleCloseSellCoinDialog'],
    setup () {
        const store = useStore()
        const $router = useRouter()
        // 获取余额
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    availableBalance.value = res.data.info.currency_money
                }
            })
        }
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 1032) {
                    $router.push('/home')
                    return Toast.fail(res.msg)
                }
                if(res.result == 200000) {
                    if(JSON.stringify(res.data) == '{}') {
                        Dialog.confirm({
                            title: '提示',
                            message: '请先添加收付款方式'
                        })
                        .then(() => {
                            $router.push('/payment')
                        })
                        .catch(() => {
                            $router.push('/home')
                        });
                    } else {
                        for (let key in res.data) {
                            if(res.data[key].status == 1) {
                                paymentWayList.value.push(res.data[key].type)
                            }
                        }
                    }
                }
            })
        }
        onMounted(() => {
            handleGetMemberAmount()
            handleGetPaymentList()
        })
        // 定义出售的最少数量
        const minSaleAmount = ref(Number(store.state.sysConfig.min_amount))
        // 可售余额
        const availableBalance = ref(0)
        // 只能输入整数跟2位小数
        const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
        // 出售数量
        const saleAmountValue = ref('')
        // 展示出售数量
        const labelSaleAmount = ref(0)
        // 输入框输入值
        const handleUpdateAmount = (value) => {
            if (!value) {
                labelSaleAmount.value = 0
            } else {
                labelSaleAmount.value = value
            }
        }
        // 定义收款方式列表
        const paymentWayList = ref([])
        // 存储多选的收款方式id值
        const paymentIdArr = ref([])
        // 选择收款方式
        const selectPaymentWay = (i) => {
            if(paymentIdArr.value.includes(i)){
                //includes()方法判断是否包含某一元素,返回true或false表示是否包含元素，对NaN一样有效
                //filter()方法用于把Array的某些元素过滤掉，filter()把传入的函数依次作用于每个元素，然后根据返回值是true还是false决定保留还是丢弃该元素：生成新的数组
                paymentIdArr.value = paymentIdArr.value.filter(function (ele){return ele != i;});
            }else{
                paymentIdArr.value.push(i);
            }
        }
        // 出售方式
        const sellWayId = ref(1)
        // 是否显示支付密码弹框
        const showPayPasswordDialog = ref(false)
        // 支付密码变量
        const pay_password = ref('')
        // 取消默认关闭弹框事件
        const onBeforeClose = (action) => {
            // 点击了确定按钮
            if (action === "confirm") {
                if (!pay_password.value) {
                    Toast.fail('请输入支付密码')
                    return false
                } else {
                    handleDoSale()
                }
            }
            // 点击了取消按钮
            else {
                showPayPasswordDialog.value = false
                pay_password.value = ''
            }
        }
        // 点击出售按钮
        const handleSale = () => {
            if (btnClock.value) return
            if(!saleAmountValue.value) {
                return Toast.fail('请输入出售数量')
            } else if(saleAmountValue.value < minSaleAmount.value) {
                return Toast.fail('出售数量不能小于单次最小数量')
            } else if(!paymentIdArr.value.length) {
                return Toast.fail('请选择收款方式')
            } else {
                showPayPasswordDialog.value = true
            }
            btnClock.value = true
        }
        // 确定出售
        const handleDoSale = () => {
            btnClock.value = true
            let params = {
                amount: saleAmountValue.value,
                pay_type: paymentIdArr.value.sort((a,b)=>{return a-b}).toString(),
                type: sellWayId.value,
                pay_password: pay_password.value
            }
            doSell(params).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    showPayPasswordDialog.value = false
                    pay_password.value = ''
                    Toast.success('上架成功')
                    saleAmountValue.value = ''
                    paymentIdArr.value = []
                    labelSaleAmount.value = 0
                    sellWayId.value = 1
                    handleGetMemberAmount()
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示卖币弹窗
        const showSellDialog = ref(true)
        return {
            minSaleAmount,
            availableBalance,
            saleAmountValue,
            labelSaleAmount,
            handleUpdateAmount,
            paymentWayList,
            selectPaymentWay,
            paymentIdArr,
            sellWayId,
            handleSale,
            formatter,
            btnClock,
            themeImgs,
            showPayPasswordDialog,
            pay_password,
            onBeforeClose,
            store,
            showSellDialog
        }
    }
}
</script>

<style lang="less" scoped>
.sellCoin-wrap {
    :deep(.sellcoin-dialog) {
        width: 506px;
        height: 343px;
        top: 50%;
        .sellCoin-main-wrap {
            position: relative;
            width: 506px;
            height: 343px;
            background: url('../assets/images/sellcoin/sellcoin-bg.png') no-repeat;
            background-size: 100% 100%;
            .close-btn {
                position: absolute;
                top: 24px;
                right: 0;
                width: 32px;
                height: 32px;
            }
            .sellCoin-main-box {
                padding: 80px 80px 0;
                .van-cell {
                    width: 100%;
                    height: 50px;
                    background: #0B1D4E;
                    border-radius: 8px;
                    .van-field__left-icon {
                        width: 32px;
                        height: 32px;
                        img {
                            width: 32px;
                            height: 32px;
                        }
                    }
                    .van-field__label {
                        width: 120px;
                        font-family: 'PingFang SC';
                        font-weight: 500;
                        font-size: 26px;
                        color: #FFD65A;
                    }
                }
                .sale-amount {
                    margin-top: 10px;
                    font-family: 'PingFang SC';
                    font-weight: 500;
                    color: #FFD65A;
                    line-height: 20px;
                }
                .payment-method-wrap {
                    display: flex;
                    align-items: center;
                    margin-top: 10px;
                    .label {
                        margin-right: 24px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #fff;
                    }
                    .payment-method-box {
                        display: flex;
                        align-items: center;
                        .method-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 40px;
                            height: 30px;
                            margin-right: 20px;
                            &.active {
                                border: 1px solid #3A9FFF;
                                border-radius: 8px;
                            }
                            img {
                                width: 24px;
                                height: 24px;
                            }
                        }
                    }
                }
                .sale-method-wrap {
                    display: flex;
                    align-items: center;
                    margin-top: 14px;
                    .label {
                        margin-right: 24px;
                        font-weight: 500;
                        font-size: 16px;
                        color: #fff;
                    }
                    .sale-method-box {
                        display: flex;
                        align-items: center;
                        .method-item {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            width: 70px;
                            height: 30px;
                            margin-right: 20px;
                            background: url('../assets/images/sellcoin/split-bg.png') no-repeat;
                            border-radius: 8px;
                            text-shadow: 0px 0.955357px 0.955357px #305A9D;
                            &.active {
                                background: url('../assets/images/sellcoin/active-split-bg.png') no-repeat;
                            }
                        }
                    }
                }
                .sale-btn-box {
                    margin-top: 20px;
                    .van-button {
                        width: 216px;
                        height: 31px;
                        margin: auto;
                        background: url('../assets/images/common/btn-bg.png') no-repeat;
                        background-size: 100% 100%;
                        border-radius: 8px;
                    }
                }
            }
        }
    }
    :deep(.pay-password-dialog) {
        width: 320px;
        height: 203px;
        top: 50%;
        padding: 70px 40px 0;
        background: url('../assets/images/sellcoin/pay-pwd-bg.png') no-repeat;
        background-size: 100% 100%;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .van-cell {
            width: 100%;
            background: #293966;
            border-radius: 8px;
        }
        .pay-btn {
            height: 31px;
            margin: 57px auto 0;
            background: url('../assets/images/common/btn-bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 8px;
        }
    }
}
</style>