<template>
    <div class="editPersonalInfo-wrap">
        <van-nav-bar
            title="个人资料"
            left-arrow
            :border="false"
            @click-left="onClickLeft"
        />
        <div class="editPersonalInfo-main-wrap">
            <van-cell title="设置头像" center is-link @click="showAvatarDialog = true">
                <template #value>
                    <van-image round class="avatar-img" :src="presonalInfoObj.avatar" />
                </template>
            </van-cell>
            <van-cell title="修改昵称" :value="presonalInfoObj.nick_name" center is-link @click="showNickNamePop=true" />
        </div>
        <!-- 修改头像 -->
        <van-dialog class="editPersonalInfo-avatar-dialog" v-model:show="showAvatarDialog" title="修改头像" show-cancel-button @confirm="handleUpdateAvatar">
            <van-uploader class="editPersonalInfo-uploader" :max-count="1" v-model="uploadFile" :after-read="afterUpload" />
        </van-dialog>
        <!-- 修改昵称 -->
        <van-popup
            class="showPop-wrap"
            v-model:show="showNickNamePop"
            round
            position="bottom"
        >
            <div class="pop-head">
                <div class="pop-cancle-btn" @click="showNickNamePop=false">取消</div>
                <div class="pop-title">修改昵称</div>
                <div class="pop-comfirm-btn" @click="handleUpdateNickName">完成</div>
            </div>
            <div class="pop-content">
                <van-field v-model="newNickName" placeholder="请输入新的昵称" clearable autocomplete="off"  />
            </div>
        </van-popup>
    </div>
</template>

<script>
import { onMounted, ref } from 'vue'
import { useStore } from 'vuex'
import { upAvatar, upNickName,uploadImage,getMemberInfo } from '@/api/api'
import { Toast } from 'vant'

export default {
    setup () {
        const store = useStore()
        // 点击导航栏左侧返回键/取消按钮
        const onClickLeft = () => {
            history.back()
        }
        const presonalInfoObj = ref({})
        // 修改头像相关
        let showAvatarDialog = ref(false)
        // 存储上传头像后得到的文件流
        const uploadFile = ref([])
        // 存储上传头像后得到的头像地址
        const avatarUrl = ref('')
        // 上传头像后
        const afterUpload = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'avatar')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    avatarUrl.value = res.data.path
                } else {
                    Toast.fail(res.msg)
                }
            })
        };
        // 修改昵称
        const showNickNamePop = ref(false)
        const newNickName = ref('')
        // 获取个人资料
        const handleGetUserInfo = () => {
            getMemberInfo().then(res => {
                if (res.result == 200000) {
                    presonalInfoObj.value = res.data
                    store.dispatch('saveUserInfosActions', res.data)
                }
            })
        }
        onMounted(() => {
            handleGetUserInfo()
        })
        // 修改头像
        const handleUpdateAvatar = () => {
            upAvatar({avatar: avatarUrl.value}).then(res => {
                if(res.result == 200000) {
                    Toast.success('修改成功')
                    uploadFile.value = []
                    handleGetUserInfo()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 修改昵称
        const handleUpdateNickName = () => {
            if(!newNickName.value) {
                return Toast.fail('请输入新的昵称')
            }
            upNickName({nick_name:newNickName.value}).then(res => {
                if(res.result == 200000) {
                    showNickNamePop.value = false
                    newNickName.value = ''
                    Toast.success('修改成功')
                    handleGetUserInfo()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        return {
            onClickLeft,
            presonalInfoObj,
            showAvatarDialog,
            afterUpload,
            uploadFile,
            handleUpdateAvatar,
            handleUpdateNickName,
            showNickNamePop,
            newNickName
        }
    }
}
</script>

<style lang="less" scoped>
.editPersonalInfo-wrap {
    .editPersonalInfo-main-wrap {
        height: calc(100vh - 46px);
        background: var(--mainBgColor);
        .avatar-img {
            width: 46px;
            height: 46px;
        }
        .van-cell {
            min-height: 50px;
            background: var(--myCellBgColor);
        }
    }
    :deep(.editPersonalInfo-avatar-dialog) {
        .van-dialog__header {
            color: #fff;
        }
        .van-dialog__content {
            padding: 20px 0;
            text-align: center;
        }
    }
    :deep(.showPop-wrap) {
        .pop-head {
            display: flex;
            align-items: center;
            justify-content: space-between;
            height: 56px;
            padding: 0 16px;
            color: var(--textCommonColor);
            .pop-title {
                font-weight: 500;
                font-size: 17px;
            }
            .pop-comfirm-btn {
                font-weight: 600;
            }
        }
        .pop-content {
            display: flex;
            align-items: center;
            height: 96px;
            margin-top: 8px;
            padding: 0 30px;
            .van-cell {
                border-radius: 6px;
                background: var(--inputBgColor);
            }
        }
    }
}
</style>