<template>
    <div class="fromThirdPay-wrap">
      <div class="money-box">
        <p class="money">{{payDataParams.amount}}</p>
        <p class="label">充值金额</p>
        <div class="order-time-box">
          <span class="tip">订单时效：</span>
          <span class="downtime">{{downTime}}</span>
        </div>
      </div>
      <div class="order-detail-box">
        <div class="channel">{{payDataParams.channel}}</div>
        <div class="order-num-box">
          <span class="label">订单编号：</span>
          <div class="num-box">
            <span>{{payDataParams.order_sn}}</span>
            <van-image class="copy" :src="require(`../assets/fromThird/copy-icon.png`)" @click="handleCopy(payDataParams.order_sn)" />
          </div>
        </div>
        <div class="order-num-box">
          <span>订单时间：</span>
          <span>{{payDataParams.order_time}}</span>
        </div>
        <div class="order-num-box">
          <span>余额：</span>
          <span>{{payDataParams.member_amount}}</span>
        </div>
      </div>
      <div class="btn-box">
        <div class="btn btn1" @click="goUrl">获得更多AI币</div>
        <div class="btn btn2" @click="showPayPasswordDialog=true">确认支付</div>
      </div>
      <!-- 支付密码弹出框 -->
      <van-dialog v-model:show="showPayPasswordDialog" class="formThirdPay-payPassword-dialog" :show-confirm-button="false">
          <div class="close-btn" @click="onBeforeClose('close')"></div>
          <van-field
              v-model="payDataParams.pay_password"
              type="password"
              label="支付密码"
              placeholder="请输入支付密码"
              maxlength="6"
              clearable
          />
          <van-button class="pay-btn" block @click="onBeforeClose('confirm')">确定</van-button>
      </van-dialog>
      <!-- 余额不足弹出框 -->
      <van-dialog v-model:show="showNoMoneyDialog" class="formThirdPay-noMoney-dialog" title="温馨提示" :show-confirm-button="false">
          <p class="tips-content">您的余额不足，清充值</p>
          <div class="btn-box">
            <div class="btn btn1" @click="showNoMoneyDialog=false">取消</div>
            <div class="btn btn2" @click="goUrl">去充值</div>
          </div>
      </van-dialog>
    </div>
</template>

<script setup>
import { reactive, ref, onMounted, onBeforeMount} from 'vue'
import { fromThirdPay } from "@/api/api"
import useClipboard from 'vue-clipboard3'
import { Toast } from 'vant'
import { useRouter } from "vue-router"
import { useStore } from 'vuex'

const store = useStore()
const $router = useRouter()
// 盘口标识
const siteName = ref(siteChannel)

// 当前支付数据接口请求参数
const payDataParams = reactive({
  channel: '',
  three_username: '',
  actname: '',
  mcode: '',
  time: '',
  ip: '',
  order_sn: '',
  member_amount: '',
  order_time: '',
  password: '',
  pay_password: '',
  sign: '',
  amount: '',
  notifyUrl: ''
})
// 封装获取url参数
const getUrlParams = (url) => {
  let urlStr = url.split('?')[1]
  const urlSearchParams = new URLSearchParams(urlStr)
  const result = Object.fromEntries(urlSearchParams.entries())
  return result
}
const curToken = ref('')
// 获取支付数据
const getPayData = () => {
  payDataParams.channel = getUrlParams(location.href)['channel']
  payDataParams.three_username = getUrlParams(location.href)['three_username']
  payDataParams.actname = getUrlParams(location.href)['actname']
  payDataParams.ip = getUrlParams(location.href)['ip']
  payDataParams.mcode = getUrlParams(location.href)['mcode']
  payDataParams.time = getUrlParams(location.href)['time']
  payDataParams.sign = getUrlParams(location.href)['sign']
  payDataParams.order_sn = getUrlParams(location.href)['order_sn']
  payDataParams.member_amount = getUrlParams(location.href)['member_amount']
  payDataParams.amount = getUrlParams(location.href)['amount']
  payDataParams.notifyUrl = getUrlParams(location.href)['notifyUrl']
  curToken.value = getUrlParams(location.href)['token']
  payDataParams.order_time = timestampToTime(Number(getUrlParams(location.href)['order_time']), true)
}
// 时间戳转换日期格式方法
function timestampToTime(timestamp, state = false) {
    var date = new Date(timestamp * 1000);//时间戳为10位需*1000，时间戳为13位的话不需乘1000
    var Y = date.getFullYear() + '-';
    var M = ((date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : '0' + (date.getMonth() + 1)) + '-';
    var D = (date.getDate() > 9 ? date.getDate() : '0' + date.getDate());
    var h = (date.getHours() > 9 ? date.getHours() : '0' + date.getHours()) + ':';
    var m = (date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes()) + ':';
    var s = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    return state ? Y + M + D + ' ' + h + m + s : Y + M + D;
}

// 倒计时时间
const downTime = ref('00:00')
// 定时器
let downTimer = null
// 倒计时函数
const timeDown = (time) => {
  clearInterval(downTimer)
  if(time<=0) return
    downTimer = setInterval(() => {
    time--
    if(time<=-1) {
        clearInterval(downTimer)
    } else {
        let second = Math.floor(time % 60);     // 计算秒 ，取余
        let minite = Math.floor((time / 60) % 60); //计算分 ，换算有多少分，取余，余出多少秒
        minite = minite.toString().length == 1 ? '0' + minite : minite;
        second = second.toString().length == 1 ? '0' + second : second;
        downTime.value = minite + ":" + second
    }
  },1000)
}
onBeforeMount(() => {
  getPayData()
})
onMounted(()=> {
  timeDown(getUrlParams(location.href)['order_time'])
})
// 跳转首页
const goUrl = () => {
  store.dispatch("saveLoginInfosActions", {token:curToken.value});
  $router.push('/home')
  localStorage.setItem('isFromThird', true)
  let urlStr = window.location.href.split('?')[1]
  sessionStorage.setItem('fromThirdUrlStr', urlStr)
}
// 复制
const { toClipboard } = useClipboard()
const handleCopy = async(content) => {
    try {
        await toClipboard(content)
        Toast.success('复制成功')
    } catch (e) {
        console.error(e)
    }
}
// 是否显示支付密码框
const showPayPasswordDialog = ref(false)
// 取消默认关闭弹框事件
const onBeforeClose = (action) => {
    // 点击了确定按钮
    if (action === "confirm") {
        if (!payDataParams.pay_password) {
            return Toast.fail('请输入支付密码')
        } else {
            btnClock.value = true
            delete payDataParams.member_amount
            delete payDataParams.password
            delete payDataParams.order_time
            fromThirdPay(payDataParams).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    showPayPasswordDialog.value = false
                    payDataParams.pay_password = ''
                    Toast.success('支付成功')
                } else if (res.result == 100028) {
                  showNoMoneyDialog.value = true
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
    }
    // 点击了取消按钮
    else {
        showPayPasswordDialog.value = false
        payDataParams.pay_password = ''
        btnClock.value = false
    }
}
// 按钮锁
const btnClock = ref(false)
// 余额不足提示框
const showNoMoneyDialog = ref(false)
</script>

<style lang="less" scoped>
.fromThirdPay-wrap {
  position: absolute;
  width: 504px;
  height: 342px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding-top: 55px;
  background: url('../assets/fromThird/order-bg.png') no-repeat;
  background-size: 100% 100%;
  border-radius: 3px;
  .money-box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .money {
      font-family: 'DIN';
      font-style: normal;
      font-weight: 700;
      font-size: 36px;
      line-height: 44px;
      color: #FFD65A;
    }
    .label {
      font-family: 'PingFang SC';
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 21px;
      color: rgba(255, 255, 255, 0.6);
    }
    .order-time-box {
      display: flex;
      align-items: center;
      .tip {
        font-family: 'PingFang SC';
        color: #FFFFFF;
      }
      .downtime {
        padding-top: 2.5px;
        font-family: 'DIN';
        font-style: normal;
        font-weight: 700;
        color: #D44131;
      }
    }
  }
  .order-detail-box {
    width: 80%;
    margin: auto;
    padding: 5px 16px 10px;
    .channel {
      font-size: 16px;
      font-weight: 500;
      color: #fff;
    }
    .order-num-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      span {
        color: #FFD65A;
      }
      .num-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        .copy {
          width: 24px;
          height: 24px;
          margin-left: 8.5px;
        }
      }
    }
  }
  .btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 252px;
    margin: auto;
    .btn {
      width: 118px;
      height: 40px;
      line-height: 40px;
      text-align: center;
      &.btn1 {
        background: url('../assets/fromThird/small-cancel-btn-bg.png') no-repeat center;
        background-size: 100% 100%;
      }
      &.btn2 {
        background: url('../assets/fromThird/small-btn-bg.png') no-repeat center;
        background-size: 100% 100%;
      }
    }
  }
  :deep(.formThirdPay-payPassword-dialog) {
    width: 320px;
    height: 203px;
    top: 50%;
    padding: 70px 40px 0;
    background: url('../assets/fromThird/pay-pwd-bg.png') no-repeat;
    background-size: 100% 100%;
    border-radius: 3px;
    .close-btn {
        position: absolute;
        top: 15px;
        right: 0;
        width: 28px;
        height: 28px;
    }
    .van-cell {
        width: 100%;
        background: #293966;
        border-radius: 8px;
    }
    .pay-btn {
      height: 31px;
      margin: 57px auto 0;
      background: url('../assets/fromThird/btn-bg.png') no-repeat;
      background-size: 100% 100%;
      border-radius: 8px;
    }
  }
  :deep(.formThirdPay-noMoney-dialog) {
    background: linear-gradient(180deg, #023962 -5.65%, #121E53 100%);
    p {
      padding: 20px 0;
      text-align: center;
      color: #FFD65A;
    }
    .btn-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 252px;
      margin: 0 auto 20px;
      .btn {
        width: 118px;
        height: 40px;
        line-height: 40px;
        text-align: center;
        &.btn1 {
          background: url('../assets/fromThird/small-cancel-btn-bg.png') no-repeat center;
          background-size: 100% 100%;
        }
        &.btn2 {
          background: url('../assets/fromThird/small-btn-bg.png') no-repeat center;
          background-size: 100% 100%;
        }
      }
    }
  }
}
</style>