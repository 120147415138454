<template>
    <div class="announcement-wrap">
        <van-dialog class="announcement-dialog" v-model:show="showSiteMsgDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseSiteMsgDialog"></div>
            <div class="announcement-main-wrap">
                <div class="announcement-main-item-wrap" v-for="(item,index) in announcementData" :key="index">
                    <div class="left-wrap">
                        <img src="../assets/images/siteMsg/email-icon.png" alt="">
                        <div class="left-right-box">
                            <div class="right-top">
                                <span class="label">【系统消息】</span>
                                <span class="title">{{item.title}}</span>
                            </div>
                            <span class="date">{{item.created_at}}</span>
                        </div>
                    </div>
                    <div class="right-wrap" @click="handleOpenAnnouncementContentDialog(item)">点击查看详情</div>
                </div>
            </div>
        </van-dialog>
        <!-- 查看公告内容 -->
        <van-dialog class="announcement-content-dialog" v-model:show="showAnnouncementContentDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showAnnouncementContentDialog=false"></div>
            <div class="content-wrap">
                <div class="title-wrap">
                    <div class="title-box">
                        <img class="left-img" src="../assets/images/common/poker-left-icon.png" alt="">
                        <span class="title">{{curAnnouncementContent.title}}</span>
                        <img class="right-img" src="../assets/images/common/poker-right-icon.png" alt="">
                    </div>
                </div>
                <div class="content-box">
                    {{curAnnouncementContent.content}}
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { defineComponent,onMounted,ref } from 'vue'
import {getNewsList} from '@/api/api'

export default defineComponent({
    props:['handleCloseSiteMsgDialog'],
    setup () {
        // 显示弹框
        const showSiteMsgDialog = ref(true)
        // 获取数据
        const handleGetNewsList = () => {
            getNewsList().then(res => {
                if(res.result == 200000) {
                    announcementData.value = res.data.list
                }
            })
        }
        onMounted(() => {
            handleGetNewsList()
        })
        // 存储站内消息
        const announcementData = ref()
        // 是否显示公告内容
        const showAnnouncementContentDialog = ref(false)
        // 当前打开的公告详情
        const curAnnouncementContent = ref({})
        // 打开公告内容
        const handleOpenAnnouncementContentDialog = (item) => {
            curAnnouncementContent.value = item
            showAnnouncementContentDialog.value = true
        }
        return {
            announcementData,
            showSiteMsgDialog,
            handleOpenAnnouncementContentDialog,
            showAnnouncementContentDialog,
            curAnnouncementContent
        }
    }
})
</script>

<style lang="less" scoped>
.announcement-wrap {
    :deep(.announcement-dialog) {
        width: 508px;
        height: 332px;
        top: 50%;
        padding-top: 60px;
        background: url('../assets/images/siteMsg/announcement-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .announcement-main-wrap {
            height: 272px;
            padding: 0 8px 5px;
            overflow-y: auto;
            .announcement-main-item-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 47px;
                margin-top: 5px;
                padding: 0 18px;
                background: linear-gradient(180deg, #BCDEFF 0%, #7ABBF7 8.33%, #379CDE 92.19%, #11689F 100%);
                border-radius: 4px;
                .left-wrap {
                    display: flex;
                    align-items: center;
                    height: 100%;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                    .left-right-box {
                        .right-top {
                            .label {
                                color: #FFB904;
                                font-weight: 500;
                                font-size: 15px;
                                line-height: 15px;
                            }
                        }
                        .date {
                            padding-left: 8px;
                            font-size: 10px;
                            line-height: 10px;
                        }
                    }
                }
                .right-wrap {
                    padding: 3px 10px;
                    background: #005CA5;
                    line-height: 18px;
                    border-radius: 29px;
                    text-align: center;
                }
            }
        }
    }
    :deep(.announcement-content-dialog) {
        width: 446px;
        height: 299px;
        top: 50%;
        padding-top: 53px;
        background: url('../assets/images/siteMsg/announcement-content-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 22px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .content-wrap {
            height: 245px;
            overflow-y: auto;
            .title-wrap {
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 18px;
                .title-box {
                    display: inline-flex;
                    align-items: center;
                    background: linear-gradient(90deg, rgba(46, 106, 169, 0) 0.87%, #2E6AA9 55.64%, rgba(46, 106, 169, 0) 100%);
                    img {
                        width: 16px;
                        height: 16px;
                        margin: 0 25px;
                    }
                    .title {
                        font-weight: 500;
                        font-size: 15px;
                        line-height: 24px;
                    }
                }
            }
            .content-box {
                padding: 12px;
                line-height: 180%;
                text-indent: 2em;
            }
        }
    }
}
</style>