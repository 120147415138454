<template>
    <div class="maintenance-wrap" :style="`background:url(${themeImgs.loginRegBgImg})`">
        <h3>系统维护</h3>
        <p>维护说明：{{maintenanceObj.msg}}</p>
        <p>维护开始时间：{{maintenanceObj.start}}</p>
        <p>维护结束时间：{{maintenanceObj.end}}</p>
        <a :href="maintenanceObj.kefu_url" target="_blank">联系客服</a>
    </div>
</template>

<script>
import { ref, onMounted,computed } from "vue";
import {getMaintenance} from '@/api/api'
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
    setup () {
        const $router = useRouter();
        const store = useStore();
        // 存储维护信息
        const maintenanceObj = ref({})
        // 获取维护信息
        const handleGetMaintenance = () => {
            getMaintenance().then(res => {
                if(res.result == 200000) {
                    $router.push("/login");
                }
                if(res.result == 30000) {
                    maintenanceObj.value = res.data.info
                }
            })
        }
        onMounted(()=> {
            handleGetMaintenance()
        })
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            maintenanceObj,
            themeImgs
        }
    }
}
</script>

<style lang="less" scoped>
.maintenance-wrap {
    min-height: 100vh;
    padding: 50px 16px;
    background-repeat: no-repeat !important;
    background-size: 100% 100% !important;
    h3 {
        color: #E1CAAD;
        font-size: 20px;
        font-weight: 500;
        text-align: center;
    }
    p {
        padding: 20px 0;
        color: #fff;
        font-size: 16px;
        color: #E1CAAD;
    }
    a {
        display: block;
        height: 44px;
        margin-top: 10px;
        border-radius: 8px;
        background: var(--btnBgColor);
        text-align: center;
        line-height: 44px;
        color: #fff;
    }
}
</style>