<template>
    <div class="modifyLoginPassword-wrap">
        <van-dialog class="modifyLoginPassword-dialog" v-model:show="showUpdatePayPasswordDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseUpdatePayPasswordDialog"></div>
            <div class="modifyLoginPassword-main-wrap">
                <van-field
                    v-model="changeParams.password"
                    :type="inputPwType"
                    label="旧密码"
                    maxlength="6"
                    :formatter="formatter"
                    clearable
                    autocomplete="off"
                    placeholder="请输入旧密码"
                >
                    <template #button>
                        <span @click="changePassWordType">
                            <van-icon name="closed-eye" v-if="showEye" />
                            <van-icon name="eye-o" v-else />
                        </span>
                    </template>
                </van-field>
                <van-field
                    v-model="changeParams.new_password"
                    :type="inputNewPwType"
                    label="新密码"
                    maxlength="6"
                    :formatter="formatter"
                    clearable
                    autocomplete="off"
                    placeholder="请输入新密码"
                >
                    <template #button>
                        <span @click="changeNewPassWordType">
                            <van-icon name="closed-eye" v-if="showNewEye" />
                            <van-icon name="eye-o" v-else />
                        </span>
                    </template>
                </van-field>
                <van-field
                    v-model="changeParams.new_confirm_password"
                    :type="inputCPwType"
                    label="确认密码"
                    maxlength="6"
                    :formatter="formatter"
                    clearable
                    autocomplete="off"
                    placeholder="确认密码"
                >
                    <template #button>
                        <span @click="changeCPassWordType">
                            <van-icon name="closed-eye" v-if="showCEye" />
                            <van-icon name="eye-o" v-else />
                        </span>
                    </template>
                </van-field>
                <div class="comfirm-btn-box">
                    <van-button block @click="handleChangePassword">确认</van-button>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { ref, reactive } from 'vue'
import {changePayPassword} from '@/api/api'
import { Toast } from 'vant'

export default {
    props:['handleCloseUpdatePayPasswordDialog'],
    setup (props) {
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        const inputPwType = ref("password");
        const inputNewPwType = ref("password");
        const inputCPwType = ref("password");
        const showEye = ref(true);
        const showNewEye = ref(true);
        const showCEye = ref(true);
        const changePassWordType = () => {
            showEye.value = !showEye.value;
            if (showEye.value) {
                inputPwType.value = "password";
            } else {
                inputPwType.value = "text";
            }
        };
        const changeNewPassWordType = () => {
            showNewEye.value = !showNewEye.value;
            if (showNewEye.value) {
                inputNewPwType.value = "password";
            } else {
                inputNewPwType.value = "text";
            }
        };
        const changeCPassWordType = () => {
            showCEye.value = !showCEye.value;
            if (showCEye.value) {
                inputCPwType.value = "password";
            } else {
                inputCPwType.value = "text";
            }
        };
        const changeParams = reactive({
            password: '',
            new_password: '',
            new_confirm_password: ''
        })
        // 修改登录密码
        const handleChangePassword = () => {
            if(!changeParams.password) {
                return Toast.fail('请输入旧密码')
            } else if(!changeParams.new_password) {
                return Toast.fail('请输入新密码')
            } else if(!changeParams.new_confirm_password) {
                return Toast.fail('请再次输入新密码')
            } else if(changeParams.new_password != changeParams.new_confirm_password) {
                return Toast.fail('两次新密码不一致')
            } else {
                changePayPassword(changeParams).then(res => {
                    if(res.result == 200000) {
                        Toast.success('修改成功')
                        props.handleCloseUpdatePayPasswordDialog()
                    } else {
                        Toast.fail(res.msg)
                    }
                })
            }
            
        }
        // 显示修改登录密码弹框
        const showUpdatePayPasswordDialog = ref(true)
        return {
            formatter,
            inputPwType,
            inputNewPwType,
            inputCPwType,
            showEye,
            showNewEye,
            showCEye,
            changePassWordType,
            changeNewPassWordType,
            changeCPassWordType,
            changeParams,
            handleChangePassword,
            showUpdatePayPasswordDialog
        }
    }
}
</script>

<style lang="less" scoped>
.modifyLoginPassword-wrap {
    :deep(.modifyLoginPassword-dialog) {
        width: 445px;
        height: 300px;
        top: 50%;
        padding-top: 55px;
        background: url('../../assets/images/my/update-pay-pwd-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .modifyLoginPassword-main-wrap {
            padding: 20px 66px;
            .van-cell {
                margin-bottom: 16px;
                background: #00103C;
                border-radius: 4px;
            }
            .comfirm-btn-box {
                .van-button {
                    width: 216px;
                    height: 31px;
                    margin: auto;
                    background: url('../../assets/images/common/btn-bg.png') no-repeat;
                    background-size: 100% 100%;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>