<template>
    <div class="order-wrap">
        <van-dialog class="agencyCommission-dialog" v-model:show="showAgencyCommissionDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseAgencyCommissionDialog"></div>
            <div class="first-tab-wrap">
                <div class="tab-item" :class="{'active':firstTabIndex==''}" @click="changeFirstTab('')">全部</div>
                <div class="tab-item" :class="{'active':firstTabIndex==1}" @click="changeFirstTab(1)">买单佣金</div>
                <div class="tab-item" :class="{'active':firstTabIndex==2}" @click="changeFirstTab(2)">卖单佣金</div>
            </div>
            <div class="order-main-wrap">
                <div class="date-wrap">
                    <div class="date-box" @click="showDateCalendar=true">
                        <div class="date-item-box">
                            <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                            <span class="date">{{startDate}}</span>
                            <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                        </div>
                        <p>至</p>
                        <div class="date-item-box">
                            <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                            <span class="date">{{endDate}}</span>
                            <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                        </div>
                    </div>
                    <van-field v-model="apiParams.order_sn" autocomplete="off" clearable placeholder="请输入搜索的订单编号">
                        <template #button>
                            <van-button size="small" @click="handleSearch">搜索</van-button>
                        </template>
                    </van-field>
                </div>
                <div class="order-content-wrap" v-if="rebateList.length>0">
                    <div class="commission-paid">
                        已派发佣金：{{commissionPaidMoney}}{{store.state.publicConfig.title}}
                    </div>
                    <van-list
                        v-model:loading="loadingMore"
                        :finished="finishedMore"
                        finished-text="没有更多了"
                        @load="onLoadMore"
                        :immediate-check="false"
                    >
                        <div class="order-item-box" v-for="(item,index) in rebateList" :key="index">
                            <div class="item-box">
                                <span class="label">订单号：</span>
                                <span class="content">{{item.order_sn}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣账号：</span>
                                <span class="content">{{item.username}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">交易金额：</span>
                                <span class="content">{{item.amount}} {{store.state.publicConfig.title}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">买卖类型：</span>
                                <span class="content">{{item.type==1?'买单':'卖单'}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣比例：</span>
                                <span class="content">{{item.rebate}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣金额：</span>
                                <span class="content">{{item.rebate_amount}} {{store.state.publicConfig.title}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣方式：</span>
                                <span class="content">{{item.rebate_type==1?'日结':item.rebate_type==2?'周结':'月结'}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣时间：</span>
                                <span class="content">{{item.updated_at}}</span>
                            </div>
                            <div class="item-box">
                                <span class="label">返佣派发：</span>
                                <span class="content" :class="{'active' : item.status==1}">{{item.status==1?'已返佣':'未返佣'}}</span>
                            </div>
                        </div>
                    </van-list>
                </div>
                <van-empty
                    v-else
                    class="custom-image"
                    :image="require('../../assets/images/common/empty-img.png')"
                    description="暂无数据～"
                />
            </div>
        </van-dialog>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
    </div>
</template>

<script>
import { reactive, ref, onMounted,computed } from 'vue'
import {getRebateList} from '@/api/api'
import moment from 'moment'
import useClipboard from 'vue-clipboard3'
import { Toast } from 'vant'
import { useStore } from "vuex";

export default {
    props: ['handleCloseAgencyCommissionDialog'],
    setup () {
        const store = useStore()
        // 第一层tab索引值
        const firstTabIndex = ref('')
        // 点击第一层tab
        const changeFirstTab = id => {
            firstTabIndex.value = id
            startDate.value = moment().format("YYYY-MM-DD")
            endDate.value = moment().format("YYYY-MM-DD")
            apiParams.pageSize = 20
            apiParams.order_sn = ''
            rebateList.value = []
            handleGetRebateList()
        }
        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            handleGetRebateList()
        }
        // 存储返佣列表数据
        const rebateList = ref([])
        // 存储已返佣的金额
        const commissionPaidMoney = ref()
        // 存储接口默认请求参数
        const apiParams = reactive({
            type: 1,
            order_sn: '',
            page: 1,
            pageSize: 20,
            start: `${startDate.value} 00:00:00`,
            end: `${endDate.value} 23:59:59`
        })
        // 获取返佣列表数据
        const handleGetRebateList = (more) => {
            apiParams.type = firstTabIndex.value
            apiParams.start = `${startDate.value} 00:00:00`
            apiParams.end = `${endDate.value} 23:59:59`
            if(more) {
                apiParams.pageSize+=20
            }
            getRebateList(apiParams).then(res => {
                if(res.result == 200000) {
                    commissionPaidMoney.value = res.data.list.count_amount
                    if(!more) {
                        rebateList.value = res.data.list.list
                        if(res.data.list.list.length>=res.data.list.count) {
                            finishedMore.value = true
                        }
                    } else {
                        rebateList.value = res.data.list.list
                        loadingMore.value = false
                        if(res.data.list.list.length>=res.data.list.count) {
                            finishedMore.value = true
                        }
                    }
                }
            })
        }
        onMounted(() => {
            handleGetRebateList()
        })
        // 订单状态过滤器
        const orderStatusFilter = (status) => {
            if(status<5 || status==10) {
                return '进行中'
            }
            if(status==5 || status==9) {
                return '已完成'
            }
            if(status==6 || status==7 || status==8) {
                return '已取消'
            }
            if (status == 12) {
                return '人工退回'
            }
            if (status == 13) {
                return '人工打币'
            }
        }
        // 挂单状态过滤器
        const hangOrderStatusFilter = (status) => {
            if(status==1) {
                return '出售中'
            }
            if(status==2) {
                return '已取消'
            }
            if(status==3) {
                return '已完成'
            }
            if(status==4) {
                return '已冻结'
            }
            if(status==5) {
                return '交易中'
            }
        }
        // api订单状态过滤器
        const apiOrderStatusFilter = (status) => {
            if(status==1) {
                return '待支付'
            }
            if(status==2) {
                return '成功'
            }
            if(status==3) {
                return '失败'
            }
        }
        // 上拉加载
        const loadingMore = ref(false);
        const finishedMore = ref(false);
        const onLoadMore = () => {
            handleGetRebateList(true)
        };
        // 复制
        const { toClipboard } = useClipboard()
        const handleCopy = async(content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 搜索
        const handleSearch = () => {
            handleGetRebateList()
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示代理佣金弹框
        const showAgencyCommissionDialog = ref(true)
        return {
            firstTabIndex,
            changeFirstTab,
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            rebateList,
            commissionPaidMoney,
            orderStatusFilter,
            hangOrderStatusFilter,
            loadingMore,
            finishedMore,
            onLoadMore,
            handleCopy,
            handleSearch,
            apiParams,
            apiOrderStatusFilter,
            themeImgs,
            store,
            showAgencyCommissionDialog
        }
    }
}
</script>

<style lang="less" scoped>
.order-wrap {
    :deep(.agencyCommission-dialog) {
        width: 445px;
        height: 320px;
        top: 50%;
        padding: 55px 16px 0;
        background: url('../../assets/images/my/agency-commission-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .first-tab-wrap {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 10px 0;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
            .tab-item {
                height: 36px;
                line-height: 36px;
                color: var(--textCommonColor);
                &.active {
                    color: #FFD65A;
                    font-weight: 600;
                }
            }
        }
        .order-main-wrap {
            height: 205px;
            overflow: auto;
            .date-wrap {
                padding: 12px;
                .date-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    width: 100%;
                    height: 36px;
                    padding: 0 20px;
                    background: #0B1E4E;
                    border-radius: 17px;
                    .date-item-box {
                        display: flex;
                        align-items: center;
                        .van-image {
                            width: 16px;
                            height: 16px;
                        }
                        p {
                            font-size: 16px;
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                        .date {
                            padding: 0 10px;
                            font-size: 16px;
                            font-family: 'DINOffcPro';
                            color: var(--textCommonColor);
                            opacity: 0.9;
                        }
                    }
                }
                .van-cell {
                    margin-top: 10px;
                    padding-top: 0;
                    padding-bottom: 0;
                    padding-right: 0;
                    background: #0B1E4E;
                    border-radius: 17px;
                }
            }
            .order-content-wrap {
                height: 100%;
                padding: 0 24px 106px;
                color: var(--textCommonColor);
                overflow: scroll;
                .order-item-box {
                    display: block;
                    padding: 8px 0;
                    border-bottom: 1px solid #eee;
                    &:last-child {
                        border-bottom: none;
                    }
                    .item-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        height: 30px;
                        .content {
                            &.active {
                                color: #E1CAAD;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>