<template>
    <div class="uploadVoucher-wrap">
        <van-dialog class="uploadVoucher-dialog" v-model:show="showUploadVoucherDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseUploadVoucherDialog"></div>
            <div class="uploadVoucher-main-wrap">
                <div class="upload-box">
                    <van-uploader v-model="voucherFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUploadVoucher" />
                    <span>上传支付凭证</span>
                </div>
                <div class="start-btn-box">
                    <van-button block @click="handleUploadPaymentVoucher">我已转账</van-button>
                </div>
            </div>
        </van-dialog>
        <van-overlay class-name="uploadVoucher-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
    </div>
</template>

<script>
import { ref,computed } from 'vue'
import {uploadPaymentVoucher,uploadImage,comfirmBuyerTransfer} from '@/api/api'
import { useRoute,useRouter } from 'vue-router'
import { Toast } from 'vant'
import { useStore } from 'vuex'

export default {
    props: ['handleCloseUploadVoucherDialog', 'orderSn'],
    setup (props) {
        const $route = useRoute()
        const $router = useRouter()
        const store = useStore()
        // 定义在线客服地址
        const serviceUrl = ref(store.state.sysConfig.manage)
        // 是否显示蒙层
        const showOverlay = ref(false)
        // 存储上传买家付款凭证后得到的图片地址
        const voucherImgUrl = ref('')
        // 存储上传买家付款凭证后得到的文件流
        const voucherFileList = ref([])
        // 上传买家付款凭证前
        const beforeUpload = () => {
            showOverlay.value = true
            return true
        }
        // 上传买家付款凭证后
        const afterUploadVoucher = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'certificate')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    voucherImgUrl.value = res.data.path
                    showOverlay.value = false
                } else {
                    showOverlay.value = false
                    Toast.fail(res.msg)
                    voucherImgUrl.value = ''
                    voucherFileList.value = []
                }
            })
        };
        // 确定上传凭证
        const handleUploadPaymentVoucher = () => {
            uploadPaymentVoucher({order_sn: props.orderSn,pay_pic:voucherImgUrl.value}).then(res=> {
                if(res.result == 200000) {
                    handleComfirmBuyerTransfer()
                    Toast.success('上传成功')
                    props.handleCloseUploadVoucherDialog(props.orderSn)
                    store.commit('setUntreatedNum', 0)
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 买家确认转账
        const handleComfirmBuyerTransfer = () => {
            comfirmBuyerTransfer({order_sn: props.orderSn})
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示买家上传付款凭证弹框
        const showUploadVoucherDialog = ref(true)
        return {
            voucherFileList,
            afterUploadVoucher,
            handleUploadPaymentVoucher,
            showOverlay,
            beforeUpload,
            serviceUrl,
            themeImgs,
            showUploadVoucherDialog
        }
    }
}
</script>

<style lang="less" scoped>
.uploadVoucher-wrap {
    :deep(.uploadVoucher-dialog) {
        width: 445px;
        height: 290px;
        top: 50%;
        padding-top: 45px;
        background: url('../../assets/images/order/upload-voucher-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 13px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .uploadVoucher-main-wrap {
            height: 245px;
            padding: 20px 50px 20px;
            .upload-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 343px;
                height: 143px;
                background: #2B4F87;
                border-radius: 8px;
                .van-uploader {
                    .van-uploader__upload {
                        width: 72px;
                        height: 72px;
                        background: rgba(255, 255, 255, 0.3);
                        border-radius: 50%;
                        margin: 0;
                        .van-uploader__upload-icon {
                            color: #fff;
                        }
                    }
                    .van-uploader__preview {
                        margin: 0;
                        .van-uploader__preview-image {
                            width: 72px;
                            height: 72px;
                        }
                    }
                }
                span {
                    margin-top: 10px;
                    font-weight: 500;
                    font-size: 16px;
                }
            }
            .start-btn-box {
                margin-top: 20px;
                border-radius: 8px;
                .van-button {
                    width: 216px;
                    height: 41px;
                    margin: auto;
                    background: url('../../assets/images/common/btn-bg.png') no-repeat;
                    background-size: 100% 100%;
                    border-radius: 8px;
                }
            }
        }
    }
    .uploadVoucher-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>
