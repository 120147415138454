<template>
    <div class="personalReport-wrap">
        <van-dialog class="report-dialog" v-model:show="showPersonalReportDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleClosePersonalReportDialog"></div>
            <div class="personalReport-main-wrap">
                <div class="date-box" @click="showDateCalendar=true">
                    <div class="date-item-box">
                        <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                        <span class="date">{{startDate}}</span>
                        <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                    </div>
                    <p>至</p>
                    <div class="date-item-box">
                        <van-image :src="require('../../assets/images/common/calendar_icon.png')" />
                        <span class="date">{{endDate}}</span>
                        <van-image :src="require('../../assets/images/common/default-down-arrow-icon.png')" />
                    </div>
                </div>
                <div class="report-list-wrap">
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">买单总额</span>
                            <span class="amount">{{reportData.buy_amount}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">卖单总额</span>
                            <span class="amount">{{reportData.sell_amount}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">API充值总额</span>
                            <span class="amount">{{reportData.recharge_amount}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">API充值返点</span>
                            <span class="amount">{{reportData.recharge_rebate}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">API提现总额</span>
                            <span class="amount">{{reportData.withdraw_amount}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">交易纠纷订单总额</span>
                            <span class="amount">{{reportData.dispute_amount}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">转账总额</span>
                            <span class="amount">{{reportData.transfer_out}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                    <div class="list-item-box">
                        <div class="item-box">
                            <span class="label">收款总额</span>
                            <span class="amount">{{reportData.transfer_in}}币</span>
                        </div>
                        <div class="slider"></div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 日历插件 -->
        <van-calendar v-model:show="showDateCalendar" type="range" allow-same-day :show-confirm="false" :min-date="minDate" :max-date="maxDate" @confirm="handleGetStartEndDate" />
    </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue'
import {getReport} from '@/api/api'
import moment from 'moment'
import { useStore } from "vuex";

export default {
    props: ['handleClosePersonalReportDialog'],
    setup () {
        const store = useStore()
        // 请求数据接口
        const handleGetReport = () => {
            let params = {}
            params.start = `${startDate.value} 00:00:00`
            params.end = `${endDate.value} 23:59:59`
            getReport(params).then(res => {
                if(res.result == 200000) {
                    reportData.value = res.data.list
                }
            })
        }
        onMounted(() => {
            handleGetReport()
        })
        // 存储内容
        const reportData = ref('')
        // 是否显示日历插件
        const showDateCalendar = ref(false)
        // 存储开始日期
        const startDate = ref(moment().format("YYYY-MM-DD"))
        // 存储结束日期
        const endDate = ref(moment().format("YYYY-MM-DD"))
        // 日期组件的最小日期--半年前
        const minDate = new Date(new Date().setMonth(new Date().getMonth() - 6));
        // 日期组件的最大日期--今天
        const maxDate = new Date()
        // 选择日期区间后
        const handleGetStartEndDate = (date) => {
            const [start, end] = date
            startDate.value = moment(start).format("YYYY-MM-DD")
            endDate.value = moment(end).format("YYYY-MM-DD")
            showDateCalendar.value = false
            handleGetReport()
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示修改个人报表弹框
        const showPersonalReportDialog = ref(true)
        return {
            reportData,
            showDateCalendar,
            startDate,
            endDate,
            minDate,
            maxDate,
            handleGetStartEndDate,
            themeImgs,
            showPersonalReportDialog
        }
    }
}
</script>

<style lang="less" scoped>
.personalReport-wrap {
    :deep(.report-dialog) {
        width: 445px;
        height: 320px;
        top: 50%;
        padding-top: 60px;
        background: url('../../assets/images/my/personal-report-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .personalReport-main-wrap {
            height: 257px;
            padding: 10px 45px;
            .date-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;
                height: 36px;
                padding: 0 20px;
                background: #0B1E4E;
                border-radius: 17px;
                .date-item-box {
                    display: flex;
                    align-items: center;
                    .van-image {
                        width: 16px;
                        height: 16px;
                    }
                    p {
                        font-size: 16px;
                        opacity: 0.9;
                    }
                    .date {
                        padding: 0 10px;
                        font-size: 16px;
                        font-family: 'DINOffcPro';
                        opacity: 0.9;
                    }
                }
            }
            .report-list-wrap {
                height: 211px;
                padding: 14px 14px 0;
                overflow-y: auto;
                .list-item-box {
                    padding-bottom: 45px;
                    .item-box {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        height: 22px;
                        margin-bottom: 15px;
                        .label {
                            font-family: 'PingFang SC';
                            font-weight: 500;
                            font-size: 16px;
                        }
                        .amount {
                            font-family: 'DINOffcPro';
                            font-weight: 500;
                            font-size: 16px;
                        }
                    }
                    .slider {
                        width: 100%;
                        height: 8px;
                        background: linear-gradient(180deg, #44B9E1 0%, #3C7BBD 100%);
                        border-radius: 4px;
                    }
                }
            }
        }
    }
}
</style>