<template>
    <div class="login-wrap">
        <router-link to="/register" class="reg-btn"></router-link>
        <div class="login-box">
            <van-field
                v-model="loginParams.username"
                :formatter="formatter"
                clearable
                center
                autocomplete="off"
                placeholder="输入用户名"
                :left-icon="require('@/assets/images/common/user-icon.png')"
            />
            <van-field
                :type="inputType"
                v-model="loginParams.password"
                maxlength="16"
                :formatter="formatter"
                center
                autocomplete="off"
                placeholder="请输入密码"
                :left-icon="require('@/assets/images/common/lock-icon.png')"
            >
                <template #button>
                    <span @click="changePassWordType" class="eye-icon">
                        <van-icon name="closed-eye" v-if="showEye" />
                        <van-icon name="eye-o" v-else />
                    </span>
                </template>
            </van-field>
            <van-field
                v-if="codeType==1"
                v-model="loginParams.code"
                maxlength="4"
                :formatter="formatter"
                center
                autocomplete="off"
                placeholder="输入验证码"
            >
                <template #extra>
                    <van-image class="code-img" :src="graphicCodeImg" @click="handleGetGraphicCode" />
                </template>
            </van-field>
            <Geet ref="geetRef" v-show="codeType==3" @handleUpdateGeetStatus="handleUpdateGeetStatus" @handleGetGeetSession="handleGetGeetSession" />
            <div class="forget-box">
                <van-checkbox name='rememberMe' v-model="isRememberMe">
                    记住密码
                </van-checkbox>
                <a class="service-forget" :href="serviceUrl" target="_blank">忘记密码?</a>
            </div>
            <div class="btn login-btn-box" @click="handleLogin">登录</div>
        </div>
    </div>
</template>

<script>
import { defineComponent, ref, reactive, onBeforeMount, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { Icon, Toast } from "vant";
import { login, getGraphicCode, getCodeType, getMaintain, getScrap } from "@/api/api";
import wsConnection from "@/utils/websocket"
import Geet from "@/components/Geet"

export default defineComponent({
    components: {
        Geet,
        [Icon.name]: Icon,
    },
    setup() {
        const $router = useRouter();
        const store = useStore();
        const geetRef = ref()
        // 默认行为验证码状态
        const geetStatus = ref(1)
        // 改变行为验证码状态
        const handleUpdateGeetStatus = val => {
            geetStatus.value = val
        }
        // 存储验证码方式
        const codeType = ref('')
        // 获取验证码方式
        const handleGetCodeType = () => {
            getCodeType().then(res => {
                if(res.result == 200000) {
                    codeType.value = res.data.type
                    if(res.data.type==1) {
                        handleGetGraphicCode()
                    } else {
                        geetRef.value.init(res.data)
                        loginParams.session = res.data.session_id
                    }
                }
            })
        }
        // 获取极验证session
        const handleGetGeetSession = () => {
            handleGetCodeType()
        }
        // 存储图形验证码
        const graphicCodeImg = ref('')
        // 获取图形验证码
        const handleGetGraphicCode = () => {
            getGraphicCode().then(res => {
                if(res.result == 200000) {
                    graphicCodeImg.value = res.data.captcha
                    loginParams.session = res.data.session
                }
            })
        }
        // 存储在线客服url
        const serviceUrl = ref('')
        // 获取在线客服
        const handleGetMaintain = () => {
            getMaintain().then(res => {
                if(res.result == 200000) {
                    serviceUrl.value = res.data.info
                }
            })
        }
        onBeforeMount(()=> {
            handleGetCodeType()
            handleGetRemember()
            handleGetMaintain()
        })
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        const inputType = ref("password");
        const showEye = ref(true);
        const loginParams = reactive({
            username: "", //用户名
            password: "", //密码
            code: "", // 验证码
            session: "",
            is_captcha: 1
        });
        // 显示密码
        const changePassWordType = () => {
            showEye.value = !showEye.value;
            if (showEye.value) {
                inputType.value = "password";
            } else {
                inputType.value = "text";
            }
        };
        // 是否记住密码
        const isRememberMe = ref(true)
        const handleGetRemember = () => {
            if(isRememberMe.value) {
                loginParams.username = !!localStorage.getItem("username")?localStorage.getItem("username"):''
                loginParams.password = !!localStorage.getItem("password")?localStorage.getItem("password"):''
                loginParams.code = ''
                loginParams.session = ''
            } else {
                loginParams.username = ''
                loginParams.password = ''
                loginParams.code = ''
                loginParams.session = ''
            }

        }
        // 点击登录
        const handleLogin = () => {
            if (!loginParams.username) {
                return Toast.fail("请输入手机号码/邮箱");
            } else if (!loginParams.password) {
                return Toast.fail("请输入密码");
            } else if (codeType.value==1 && !loginParams.code) {
                return Toast.fail("请输入验证码");
            } else if (codeType.value==3 && geetStatus.value!=4) {
                return Toast.fail("请点击进行验证");
            } else {
                login(loginParams).then((res) => {
                    if (res.result == 200000) {
                        getScrap()
                        Toast.success("登录成功");
                        store.dispatch("saveLoginInfosActions", res.data);
                        $router.push("/home");
                        wsConnection.initWebSocket()
                        if(isRememberMe.value) {
                            localStorage.setItem("rememberMe", isRememberMe.value);
                            localStorage.setItem("username", loginParams.username);
                            localStorage.setItem("password", loginParams.password);
                        } else {
                            localStorage.removeItem("username");
                            localStorage.removeItem("password");
                            localStorage.removeItem("rememberMe");
                        }
                    } else {
                        if(codeType.value==3) {
                            handleGetCodeType()
                        } else {
                            handleGetGraphicCode()
                        }
                        Toast.fail(res.msg)
                    }
                }).catch(err=>{
                    console.log(err);
                })
            }
        }
        // 是否显示用户名或手机号/邮箱登录结构
        const showUsernameLogin = ref(true)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            codeType,
            graphicCodeImg,
            handleGetGraphicCode,
            formatter,
            inputType,
            showEye,
            loginParams,
            changePassWordType,
            isRememberMe,
            handleLogin,
            geetStatus,
            handleUpdateGeetStatus,
            geetRef,
            handleGetGeetSession,
            serviceUrl,
            showUsernameLogin,
            themeImgs
        };
    },
});
</script>

<style lang="less" scoped>
.login-wrap {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: 441px;
    height: 341px;
    background: url('../assets/images/login/login-bg.png') no-repeat center top;
    background-size: 100% 100%;
    .reg-btn {
        position: absolute;
        width: 120px;
        height: 30px;
        top: 37px;
        left: 213px;
    }
    .login-box {
        width: 100%;
        height: 100%;
        padding: 78px 66px 0;
        :deep(.van-cell) {
            margin-top: 17px;
            background: #00103C;
            border-radius: 4px;
            .van-field__left-icon {
                .van-icon {
                    width: 24px;
                    height: 24px;
                }
            }
            .van-field__control {
                padding-left: 10px;
            }
            input::-webkit-input-placeholder {
                color: rgba(255, 255, 255, 0.6);
            }
            .van-icon-clear {
                font-size: 18px;
            }
            .eye-icon {
                display: flex;
                align-items: center;
                color: #97A7D0;
                .van-icon {
                    font-size: 20px;
                }
            }
            .code-img {
                position: absolute;
                top: 0;
                right: 0;
                width: 100px;
                height: 34px;
            }
        }
        .forget-box {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 10px;
            height: 24px;
            :deep(.van-checkbox) {
                .van-checkbox__label {
                    color: #FFD65A;
                }
            }
            .service-forget {
                color: #FFD65A;
            }
        }
        .login-btn-box {
            width: 216px;
            height: 31px;
            margin: 16px auto 0;
            line-height: 31px;
            background: url('../assets/images/common/btn-bg.png') no-repeat;
            background-size: 100% 100%;
            text-align: center;
            text-shadow: 0px 1px 1px #305A9D;
            color: #fff;
        }
    }
}
</style>
