<template>
    <div class="home-wrap">
        <div class="home-main-wrap">
            <div class="home-top-wrap">
                <van-image class="avatar" round :src="memberInfo.avatar" :error-icon="require('../assets/images/common/default-avatar.png')" />
                <div class="info-box">
                    <span>{{memberInfo.nick_name}}</span>
                    <span>{{memberInfo.username}}</span>
                </div>
                <div class="money-box">
                    <img class="left-icon" src="../assets/images/home/money-icon.png" alt="" />
                    <span class="golden-text balance">{{memberDealInfo.currency_money || '0.00'}}</span>
                    <img class="right-icon" src="../assets/images/home/add-icon.png" alt="" />
                </div>
                <div class="address-box">
                    <div class="golden-text address">{{memberInfo.wallet_address}}</div>
                    <img class="copy-icon" src="../assets/images/common/copy-icon.png" alt="" @click="copyAddress(memberInfo.wallet_address)" />
                </div>
            </div>
            <div class="home-service-msg-box">
                <a :href="store.state.sysConfig.manage" target="_blank">
                    <img src="../assets/images/home/service-icon.png" alt="" />
                </a>
                <img src="../assets/images/home/msg-icon.png" alt="" @click="showSiteMsgDialog=true" />
            </div>
            <van-notice-bar
                class="home-notice-bar"
                :left-icon="require('../assets/images/home/notice-icon.png')"
                scrollable
                :text="announcementData || '暂无公告'"
            />
            <TradingHall :handleEmitsDoBuy="handleEmitsDoBuy" />
        </div>
        <div class="footer-wrap">
            <div class="footer-item-box" @click="handleOpenSellCoinUrl">
                <img src="../assets/images/footer/withdraw-icon.png" alt="">
                <span>提现</span>
            </div>
            <div class="footer-item-box" @click="showOrderDialog=true">
                <img src="../assets/images/footer/order-icon.png" alt="">
                <span>订单</span>
                <img v-if="showUntreated" class="untreated" src="../assets/images/footer/untreated-icon.png" alt="">
            </div>
            <div class="footer-item-box" @click="showPaymentDialog=true">
                <img src="../assets/images/footer/bind-icon.png" alt="">
                <span>绑定收款信息</span>
            </div>
            <div class="footer-item-box" @click="showAnnouncementDialog=true">
                <img src="../assets/images/footer/announcement-icon.png" alt="">
                <span>公告</span>
            </div>
            <div class="footer-item-box" @click="showPersonalCenterDialog=true">
                <img src="../assets/images/footer/my-icon.png" alt="">
                <span>我的</span>
            </div>
        </div>
        <!-- 全局交易消息提醒 -->
        <van-dialog
            v-model:show="isHaveNewOrder"
            class="global-dialog"
            :show-confirm-button="false">
            <p>你有新的订单状态，请立即处理</p>
            <div class="comfirm-btn-box">
                <van-button block @click="openUrlOrderDetails">确认</van-button>
            </div>
        </van-dialog>
        <!-- 卖币 -->
        <SellCoin v-if="showSellCoinDialog" :handleCloseSellCoinDialog="handleCloseSellCoinDialog" />
        <!-- 订单 -->
        <Order v-if="showOrderDialog" :handleCloseOrderDialog="handleCloseOrderDialog" :handleOpenOrderDetails="handleOpenOrderDetails" />
        <!-- 收付款方式 -->
        <Payment v-if="showPaymentDialog" :handleClosePaymentDialog="handleClosePaymentDialog" />
        <!-- 站内消息 -->
        <SiteMsg v-if="showSiteMsgDialog" :handleCloseSiteMsgDialog="handleCloseSiteMsgDialog" />
        <!-- 公告 -->
        <Announcement v-if="showAnnouncementDialog" :handleCloseAnnouncementDialog="handleCloseAnnouncementDialog" />
        <!-- 个人中心 -->
        <My v-if="showPersonalCenterDialog" :handleClosePersonalCenterDialog="handleClosePersonalCenterDialog" />
        <!-- 买家订单详情 -->
        <BuyerOrderDetails v-if="showBuyerOrderDetailsDialog" :orderSn="orderSn" :handleCloseBuyerOrderDetailsDialog="handleCloseBuyerOrderDetailsDialog" :handleGetUntreatedRemind="handleGetUntreatedRemind" />
        <!-- 卖家订单详情 -->
        <SellerOrderDetails v-if="showSellerOrderDetailsDialog" :orderSn="orderSn" :handleCloseSellerOrderDetailsDialog="handleCloseSellerOrderDetailsDialog" :handleGetUntreatedRemind="handleGetUntreatedRemind" />
    </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import {getPublicConfig,getMemberInfo,getPaymentList,getBulletin,getOrderRemind,getMemberAmount,logout} from '@/api/api'
import { useStore } from 'vuex'
import { Toast } from "vant";
import useClipboard from 'vue-clipboard3'
import wsConnection from "@/utils/websocket"
import SellCoin from "@/views/SellCoin"
import Order from "@/views/Order/Order"
import BuyerOrderDetails from "@/views/Order/BuyerOrderDetails"
import SellerOrderDetails from "@/views/Order/SellerOrderDetails"
import TradingHall from "@/views/tradingHall"
import Payment from "@/views/Payment/Payment"
import SiteMsg from "@/views/SiteMsg"
import Announcement from "@/views/Announcement"
import My from "@/views/My/My"

export default defineComponent({
    components: {
        Order,
        TradingHall,
        SellCoin,
        Payment,
        SiteMsg,
        Announcement,
        My,
        BuyerOrderDetails,
        SellerOrderDetails
    },
    setup() {
        const $router = useRouter()
        const store = useStore()
        // 获取会员个人信息
        const handleGetMemberInfo = () => {
            getMemberInfo().then(res => {
                if(res.result == 200000) {
                    if(res.data.status != 1) {
                        memberInfo.value = res.data
                        store.dispatch('saveUserInfosActions', res.data)
                        // 为第三方跳转来的创建与登录接口返回值对应的对象
                        store.dispatch("saveLoginInfosActions", {...store.state.loginInfos,...{username:res.data.username,ws_url:res.data.ws_url}});
                    } else {
                        handleDoLogout()
                    }
                }
            })
        }
        // 退出登录
        const handleDoLogout = () => {
            logout().then(res => {
                if(res.result == 200000) {
                    Toast.success('账号被冻结')
                    localStorage.removeItem("loginInfos")
                    if(wsConnection.$ws) {
                        wsConnection.$ws.close()
                    }
                    $router.push('/login')
                }
            })
        }
        // 获取会员交易信息
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    memberDealInfo.value = res.data.info
                    store.dispatch('saveMemberAmountActions', res.data.info)
                }
            })
        }
        // 是否显示弹窗底部按钮
        const showPrevNextBtn = ref(false)
        // 获取滚动公告,弹窗，type1为弹窗，2为滚动
        const handleGetBulletin = () => {
            getBulletin({type:2}).then(res => {
                if(res.result == 200000) {
                    if (res.data.list.length) {
                        res.data.list.forEach((item,index) => {
                            announcementData.value += `${index+1}、${item.content} `
                        })
                    }
                }
            })
        }
        // 获取公共配置
        const handleGetPublicConfig = () => {
            getPublicConfig().then(res => {
                if(res.result == 200000) {
                    store.dispatch('savePuclicConfigActions', res.data.list)
                }
            })
        }
        onMounted(() => {
            handleGetPublicConfig()
            handleGetBulletin()
            handleGetMemberInfo()
            handleGetMemberAmount()
            handleGetOrderRemind()
            handleGetPaymentList()
            if(!sessionStorage.getItem('CloseNotification')) {
                showAnnouncementDialog.value = true
            }
        })
        // 存储收付款列表数据
        const paymentList = ref({})
        // 对应卖家收付款的买家收付款方式
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 200000) {
                    paymentList.value = res.data
                }
            })
        }
        // 存储banner数据
        const bannerData = ref([])
        // 存储滚动公告
        const announcementData = ref('')
        // 存储会员个人信息
        const memberInfo = ref({})
        // 存储会员单子信息
        const memberDealInfo = ref({})
        // 点击跳转到卖币页面
        const handleOpenSellCoinUrl = () => {
            if(memberInfo.value.is_name == 1) {
                return Toast.fail('请先进行实名认证')
            }
            if(memberInfo.value.is_name == 3) {
                return Toast.fail('实名审核中')
            }
            if(memberInfo.value.is_withdraw == 1) {
                return Toast.fail('该功能已禁用')
            }
            if(JSON.stringify(paymentList.value) == '{}') {
                return Toast.fail('请添加付款方式')
            }
            showSellCoinDialog.value = true
        }
        // 复制钱包地址
        const { toClipboard } = useClipboard()
        const copyAddress = async (content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 是否显示有无订单状态
        const isHaveNewOrder = ref(false)
        // 存储订单状态提醒数据
        const orderRemind = ref([])
        // 是否显示未处理订单图标
        const showUntreated = ref(false)
        // 获取订单状态提醒
        const handleGetOrderRemind = () => {
            getOrderRemind().then(res => {
                if(res.result == 200000) {
                    orderRemind.value = res.data.list[0]
                    if(res.data.list.length>0) {
                        isHaveNewOrder.value = true
                        if(res.data.count>0) {
                            showUntreated.value = true
                        } else {
                            showUntreated.value = false
                        }
                    } else {
                        isHaveNewOrder.value = false
                    }
                }
            })
        }
        // 获取订单未处理条数提醒
        const handleGetUntreatedRemind = () => {
            getOrderRemind().then(res => {
                if(res.result == 200000) {
                    if(res.data.count>0) {
                        showUntreated.value = true
                    } else {
                        showUntreated.value = false
                    }
                }
            })
        }
        // 计算属性实现视图更新--新订单提醒
        const orderRemindObj = computed(() => store.state.orderRemind)
        watch(orderRemindObj, (newValue) =>{
            isHaveNewOrder.value = true
            orderRemind.value = newValue
            showBuyerOrderDetailsDialog.value = false
            showSellerOrderDetailsDialog.value = false
            handleGetUntreatedRemind()
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 计算属性实现视图更新--金额数量变化
        const moneyChangeObj = computed(() => store.state.moneyChangeInfos)
        watch(moneyChangeObj, (newValue) =>{
            memberDealInfo.value = newValue
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 计算属性实现视图更新--后台审核已实名
        const isName = computed(() => store.state.isName)
        watch(isName, (newValue) =>{
            handleGetMemberInfo()
        })
        // 存储订单号
        const orderSn = ref('')
        // 点击跳转订单详情页
        const openUrlOrderDetails = () => {
            orderSn.value = orderRemind.value.order_sn
            if(orderRemind.value.type==1) {
                showBuyerOrderDetailsDialog.value = true
            } else {
                showSellerOrderDetailsDialog.value = true
            }
            isHaveNewOrder.value = false
            showSellCoinDialog.value = false
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 是否显示卖币弹窗
        const showSellCoinDialog = ref(false)
        // 关闭卖币弹窗
        const handleCloseSellCoinDialog = () => {
            showSellCoinDialog.value = false
        }
        // 是否显示绑定收付款弹框
        const showPaymentDialog = ref(false)
        // 关闭绑定收付款弹框
        const handleClosePaymentDialog = () => {
            showPaymentDialog.value = false
        }
        // 是否显示站内消息弹框
        const showSiteMsgDialog = ref(false)
        // 关闭站内消息弹框
        const handleCloseSiteMsgDialog = () => {
            showSiteMsgDialog.value = false
        }
        // 是否显示公告弹框
        const showAnnouncementDialog = ref(false)
        // 关闭公告弹框
        const handleCloseAnnouncementDialog = () => {
            showAnnouncementDialog.value = false
            sessionStorage.setItem('CloseNotification', true)
        }
        // 是否显示个人中心弹框
        const showPersonalCenterDialog = ref(false)
        // 关闭个人中心弹框
        const handleClosePersonalCenterDialog = () => {
            showPersonalCenterDialog.value = false
        }
        // 是否显示订单弹框
        const showOrderDialog = ref(false)
        // 关闭订单弹框
        const handleCloseOrderDialog = () => {
            showOrderDialog.value = false
        }
        // 是否显示买家订单详情弹框
        const showBuyerOrderDetailsDialog = ref(false)
        // 关闭买家订单详情弹框
        const handleCloseBuyerOrderDetailsDialog = () => {
            showBuyerOrderDetailsDialog.value = false
        }
        // 是否显示卖家订单详情弹框
        const showSellerOrderDetailsDialog = ref(false)
        // 关闭卖家订单详情弹框
        const handleCloseSellerOrderDetailsDialog = () => {
            showSellerOrderDetailsDialog.value = false
        }
        // 接收从交易大厅传过来的订单参数
        const handleEmitsDoBuy = (order_sn) => {
            orderSn.value = order_sn
            showBuyerOrderDetailsDialog.value = true
        }
        // 接收从订单中心传过来的订单参数,从而打开对应的买卖家详情框
        const handleOpenOrderDetails = (type, sn) => {
            orderSn.value = sn
            if (type == 'buyer') {
                showBuyerOrderDetailsDialog.value = true
            } else {
                showSellerOrderDetailsDialog.value = true
            }
        }
        return {
            memberInfo,
            memberDealInfo,
            copyAddress,
            isHaveNewOrder,
            openUrlOrderDetails,
            handleOpenSellCoinUrl,
            store,
            bannerData,
            announcementData,
            themeImgs,
            store,
            showSellCoinDialog,
            handleCloseSellCoinDialog,
            showPaymentDialog,
            handleClosePaymentDialog,
            showSiteMsgDialog,
            handleCloseSiteMsgDialog,
            showAnnouncementDialog,
            handleCloseAnnouncementDialog,
            showPersonalCenterDialog,
            handleClosePersonalCenterDialog,
            showOrderDialog,
            handleCloseOrderDialog,
            showBuyerOrderDetailsDialog,
            handleCloseBuyerOrderDetailsDialog,
            showSellerOrderDetailsDialog,
            handleCloseSellerOrderDetailsDialog,
            orderSn,
            handleEmitsDoBuy,
            showUntreated,
            handleOpenOrderDetails,
            handleGetUntreatedRemind
        };
    },
});
</script>

<style lang="less" scoped>
.home-wrap {
    .home-main-wrap {
        position: relative;
        height: calc(100vmin - 44px);
        .home-top-wrap {
            position: relative;
            display: flex;
            align-items: center;
            width: 561px;
            height: 38px;
            background: url('../assets/images/home/top-bg.png') no-repeat;
            background-size: 100% 100%;
            .avatar {
                position: absolute;
                top: 0;
                left: 8px;
                width: 42px;
                height: 42px;
            }
            .info-box {
                display: flex;
                flex-direction: column;
                justify-content: center;
                height: 100%;
                padding-left: 56px;
                margin-right: 19px;
                color: #fff;
                span {
                    display: inline-block;
                    width: 110px;
                    line-height: 16px;
                    overflow: hidden;  //超出隐藏
                    white-space: nowrap; //不折行
                    text-overflow: ellipsis; //溢出显示省略号
                }
            }
            .money-box {
                position: relative;
                height: 18px;
                margin-right: 9px;
                background: rgba(0, 0, 0, 0.15);
                border-radius: 25px;
                text-align: center;
                .left-icon {
                    position: absolute;
                    left: -5px;
                    top: -5px;
                    width: 28px;
                    height: 28px;
                }
                .balance {
                    display: inline-block;
                    width: 130px;
                    padding: 0 30px;
                    overflow: hidden;  //超出隐藏
                    white-space: nowrap; //不折行
                    text-overflow: ellipsis; //溢出显示省略号
                    font-family: 'HYChangSongJ';
                }
                .right-icon {
                    position: absolute;
                    right: -5px;
                    width: 28px;
                    height: 19px;
                }
            }
            .address-box {
                position: relative;
                .address {
                    width: 154px;
                    height: 18px;
                    padding: 0 20px 0 10px;
                    line-height: 18px;
                    border-radius: 25px;
                    font-family: 'HYChangSongJ';
                    overflow: hidden;  //超出隐藏
                    white-space: nowrap; //不折行
                    text-overflow: ellipsis; //溢出显示省略号
                }
                .copy-icon {
                    position: absolute;
                    top: 0;
                    right: -5px;
                    width: 28px;
                    height: 19px;
                }
            }
        }
        .home-service-msg-box {
            position: absolute;
            top: 4px;
            right: 17px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100px;
            height: 40px;
            img {
                width: 40px;
                height: 40px;
            }
        }
        .home-notice-bar {
            height: 20px;
            margin: 20px 27px 0;
            background: linear-gradient(180deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.29) 100%);
            border-radius: 41px;
            :deep(.van-notice-bar__left-icon) {
                width: 16px;
                height: 16px;
                .van-icon__image {
                    width: 16px;
                    height: 16px;
                }
            }
        }
    }
    .footer-wrap {
        position: fixed;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 44px;
        padding: 0 113px;
        background: url('../assets/images/footer/footer-bg.png') no-repeat;
        background-size: 100% 100%;
        filter: drop-shadow(0px -1px 0px #B49887);
        .footer-item-box {
            position: relative;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 100%;
            img {
                width: 24px;
                height: 24px;
                &.untreated {
                    position: absolute;
                    top: 0;
                    right: 0;
                    width: 12px;
                    height: 12px;
                }
            }
            span {
                color: #F3D186;
                font-size: 12px;
            }
        }
    }
    .golden-text {
        background: linear-gradient(180deg, #FAEDC1 0%, #F7D253 75%, #F6D69E 98.96%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
    }
    :deep(.global-dialog) {
        width: 322px;
        height: 210px;
        top: 50%;
        padding-top: 70px;
        background: url('../assets/images/order/order-tip-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        p {
            padding: 20px 0;
            text-align: center;
            color: #fff;
        }
        .comfirm-btn-box {
            margin-top: 33px;
            .van-button {
                width: 216px;
                height: 41px;
                margin: auto;
                background: url('../assets/images/common/btn-bg.png') no-repeat;
                background-size: 100% 100%;
                border-radius: 8px;
            }
        }
    }
}
</style>
