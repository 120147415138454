<template>
    <div class="announcement-wrap">
        <van-dialog class="announcement-dialog" v-model:show="showAnnouncementDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseAnnouncementDialog"></div>
            <div class="announcement-main-wrap" v-if="announcementData.length > 1">
                <div class="left-wrap">
                    <div class="left-item-box" :class="{'active':curIndex==index}" v-for="(item,index) in announcementData" :key="index" @click="curIndex=index">
                        <span>{{item.title}}</span>
                    </div>
                </div>
                <div class="right-wrap">
                    <div class="title-wrap">
                        <div class="title-box">
                            <img class="left-img" src="../assets/images/common/poker-left-icon.png" alt="">
                            <span class="title">{{announcementData[curIndex].title}}</span>
                            <img class="right-img" src="../assets/images/common/poker-right-icon.png" alt="">
                        </div>
                    </div>
                    <div class="content">{{announcementData[curIndex].content}}</div>
                </div>
            </div>
            <van-empty
                v-else
                :image="require('../assets/images/common/empty-img.png')"
                description="暂无公告～"
            />
        </van-dialog>
    </div>
</template>

<script>
import { defineComponent,onMounted,ref } from 'vue'
import {getBulletin} from '@/api/api'

export default defineComponent({
    props:['handleCloseAnnouncementDialog'],
    setup () {
        // 显示弹框
        const showAnnouncementDialog = ref(false)
        onMounted(() => {
            handleGetBulletin()
        })
        // 存储公告数据
        const announcementData = ref([])
        // 获取滚动公告,弹窗，type1为弹窗，2为滚动
        const handleGetBulletin = () => {
            getBulletin({type:1}).then(res => {
                if(res.result == 200000) {
                    announcementData.value = res.data.list
                    showAnnouncementDialog.value = true
                }
            })
        }
        // 当前显示的公告索引值
        const curIndex = ref(0)
        return {
            showAnnouncementDialog,
            announcementData,
            curIndex
        }
    }
})
</script>

<style lang="less" scoped>
.announcement-wrap {
    :deep(.announcement-dialog) {
        width: 659px;
        height: 364px;
        top: 48%;
        padding: 65px 0 0 75px;
        background: url('../assets/images/announcement/announcement-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .announcement-main-wrap {
            display: flex;
            height: 296px;
            padding: 3px 0px 3px 3px;
            .left-wrap {
                width: 115px;
                height: 100%;
                background: linear-gradient(180deg, #023962 -5.65%, #121E53 100%);
                overflow-y: auto;
                .left-item-box {
                    position: relative;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    height: 46px;
                    background: linear-gradient(90.23deg, #306EAE 0.24%, rgba(48, 110, 174, 0) 81.02%);
                    &::after {
                        content:"";
                        position: absolute;
                        bottom: 0;
                        left: 19px;
                        width: 80px;
                        height: 1px;
                        background:  linear-gradient(90deg, rgba(57, 137, 221, 0) 1.26%, #4B8DD2 54.17%, rgba(57, 137, 221, 0) 104.38%);
                    }
                    span {
                        padding: 0 10px;
                        background: linear-gradient(180deg, #FFFFFF 0%, #ACD3EF 100%);
                        -webkit-background-clip: text;
                        -webkit-text-fill-color: transparent;
                        background-clip: text;
                        text-fill-color: transparent;
                        font-family: 'FZZhengHeiS-B-GB';
                        font-size: 15px;
                        line-height: 15px;
                        overflow:hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }
                    &.active {
                        background: url('../assets/images/common/active-bar-bg.png') no-repeat;
                        background-size: 100% 100%;
                        span {
                            background: linear-gradient(180deg, #FFFFE7 0%, #FEFEA0 100%);
                            -webkit-background-clip: text;
                            -webkit-text-fill-color: transparent;
                            background-clip: text;
                            text-fill-color: transparent;
                        }
                    }
                }
            }
            .right-wrap {
                flex: 1;
                height: 100%;
                padding: 17px 25px;
                overflow-y: auto;
                .title-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    margin-bottom: 18px;
                    .title-box {
                        display: inline-flex;
                        align-items: center;
                        background: linear-gradient(90deg, rgba(46, 106, 169, 0) 0.87%, #2E6AA9 55.64%, rgba(46, 106, 169, 0) 100%);
                        img {
                            width: 16px;
                            height: 16px;
                            margin: 0 25px;
                        }
                        .title {
                            font-weight: 500;
                            font-size: 15px;
                            line-height: 24px;
                        }
                    }
                }
                .content {
                    text-indent: 2em;
                }
            }
        }
    }
}
</style>