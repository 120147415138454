<template>
    <div class="tradingHall-wrap">
        <div class="main-left-box">
            <img src="../assets/images/tradingHall/amount-img.png" alt="" />
            <img src="../assets/images/tradingHall/payway-img.png" alt="" />
            <img src="../assets/images/tradingHall/sellway-img.png" alt="" />
        </div>
        <div class="main-right-box">
            <swiper
                v-if="sellLists.length > 0"
                slides-per-view="auto"
                :space-between="14"
                freeMode>
                <swiper-slide class="right-slide-box" v-for="(item,index) in sellLists" :key="index">
                    <div class="slide-item-top-box">
                        <van-image class="slide-avatar" round :src="item.avatar" :error-icon="require('../assets/images/common/default-avatar.png')" />
                        <span class="sell-amount">{{Number(item.currency).toFixed(2)}}</span>
                    </div>
                    <div class="slide-pay-way-box">
                        <img :src="require(`../assets/images/common/payment_icon${item1}.png`)" alt="" v-for="(item1,index1) in item.pay_type.split(',')" :key="index1" />
                    </div>
                    <div class="demolition-sale-text">{{item.type == 1 ? '可拆售' : '不可拆售'}}</div>
                    <img class="buy-btn" src="../assets/images/tradingHall/buy-btn.png" alt="" @click="openBuyCoin(item)" />
                </swiper-slide>
            </swiper>
            <van-empty
                v-if="sellLists.length < 1"
                :image="require('../assets/images/common/empty-img.png')"
                description="暂无交易数据～"
            />
        </div>
        <!-- 购买弹窗 -->
        <van-dialog class="buycoin-dialog" v-model:show="showBuyDialog" :show-confirm-button="false">
            <div class="buycoin-main-wrap">
                <div class="close-btn" @click="showBuyDialog = false;buyAmountValue=''"></div>
                <div class="buycoin-main-box">
                    <van-field center v-model="buyAmountValue" autocomplete="off" :formatter="formatter" :placeholder="`输入购买数量（${store.state.publicConfig.title}）`" :readonly="sellerInfos.type==2">
                        <template #left-icon>
                            <img src="../assets/images/common/trans-icon.png" alt="" />
                        </template>
                    </van-field>
                    <p class="buy-amount" v-if="sellerInfos.type==1">可购总数：{{sellerInfos.currency}} {{store.state.publicConfig.title}}</p>
                    <p class="buy-amount">最小购买数量：{{sellerInfos.min_amount}} {{store.state.publicConfig.title}}</p>
                    <div class="buyer-info-wrap">
                        <div class="item-box">
                            <span class="label">卖家</span>
                            <div class="right-box">
                                <van-image :src="sellerInfos.avatar" round :error-icon="require('../assets/images/common/default-avatar.png')" />
                                <span>{{sellerInfos.nick_name}}</span>
                            </div>
                        </div>
                        <div class="item-box">
                            <span class="label">出售数量</span>
                            <div class="right-box">
                                <span>{{sellerInfos.count_currency}}</span>
                            </div>
                        </div>
                        <div class="item-box">
                            <span class="label">出售方式</span>
                            <div class="right-box">
                                <span>{{sellerInfos.type == 1 ? '可拆售' : '不可拆售'}}</span>
                            </div>
                        </div>
                        <div class="item-box">
                            <span class="label">收款方式</span>
                            <div class="right-box">
                                <van-image :src="require(`../assets/images/common/payment_icon${item1}.png`)" v-for="(item1,index1) in sellerInfos.pay_type" :key="index1" />
                            </div>
                        </div>
                    </div>
                    <div class="payment-box" v-for="(item,index) in buyerPayList" :key="index">
                        <van-image :src="require(`../assets/images/common/payment_icon${item.type}.png`)" />
                        <span>{{item.type == 1 ? '支付宝' : item.type == 2 ? '微信' : '银行卡'}}</span>
                        <span>{{item.card&&accountFilter(item.card) || '******'}}</span>
                        <van-button class="pay-btn" :disabled="btnClock" block @click="handleDoBuy(item)"></van-button>
                    </div>
                </div>
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { onMounted, ref, computed, watch } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from "vue-router"
import { Toast } from 'vant'
import {getMemberAmount,getSellList,getPaymentList,getSellerInfo,doBuy} from '@/api/api'
import { Swiper, SwiperSlide } from 'swiper/vue'
import 'swiper/swiper.scss'

export default {
    components: {
        Swiper,
        SwiperSlide
    },
    props: ['handleEmitsDoBuy'],
    setup (props) {
        const store = useStore()
        const $router = useRouter()
        // 获取可售余额
        const handleGetMemberAmount = () => {
            getMemberAmount().then(res => {
                if(res.result == 200000) {
                    availableBalance.value = res.data.info.currency_money
                }
            })
        }
        onMounted(() => {
            handleGetMemberAmount()
            handleGetSellList()
            handleGetPaymentList()
        })
        // 存储可售余额
        const availableBalance = ref(0)
        // 存储售卖列表数据
        const sellLists = ref({})
        // 获取售卖列表
        const handleGetSellList = () => {
            getSellList().then(res => {
                if(res.result == 200000) {
                    sellLists.value = Object.values(res.data)
                }
            })
        }
        // 计算属性实现视图更新
        const sellInfo = computed(() => store.state.sellInfos)
        // watch监听买卖数据的变化,有消息时，不存在的数据，则新增数组对象，存在的数据，则进行相关操作
        watch(sellInfo, (newValue) => {
            var bool = sellLists.value.some(item=>{
                return item.id == newValue.id
            })
            if(bool) {
                sellLists.value.forEach((item,index) => {
                    if(item.id == newValue.id) {
                        // 修改
                        if(newValue.info_status == 2) {
                            item.currency = newValue.currency
                        }
                        // 删除
                        if(newValue.info_status == 3) {
                            sellLists.value.splice(index,1);
                        }
                    }
                })
            } else {
                // 新增
                sellLists.value.push(newValue)
            }
        }, {
            deep: true // name是一个对象，需要进行深度监听
        })
        // 存储收付款列表数据
        const paymentList = ref({})
        // 对应卖家收付款的买家收付款方式
        const buyerPayList = ref([])
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 200000) {
                    paymentList.value = res.data
                }
            })
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 是否打开购买弹框
        const showBuyDialog = ref(false)
        // 打开购买弹框
        const openBuyCoin = item => {
            if(JSON.stringify(paymentList.value) == '{}') {
                Toast.fail('请添加付款方式')
                return
            } else {
                let buyerPayArr = []
                Object.values(paymentList.value).forEach(item => {
                    buyerPayArr.push(item.type)
                })
                // 获取卖家的收款方式type值
                let sellerPayArr = item.pay_type.split(',').map(Number)
                // 判断买家方式是否包含于卖家方式中
                let bool = sellerPayArr.find(item=>buyerPayArr.includes(item))
                if(!bool) {
                    Toast.fail('请添加对应的付款方式')
                    return
                }
            }
            handleGetSellerInfos(item.id)
        }
        // 存储卖家信息
        const sellerInfos = ref({})
        // 获取卖家卖币信息
        const handleGetSellerInfos = (id) => {
            getSellerInfo({id:id}).then(res => {
                if(res.result == 200000) {
                    sellerInfos.value = res.data.list
                    if(res.data.list.type == 2) {
                        buyAmountValue.value = res.data.list.currency
                    }
                    let buyerPayArr = []
                    Object.values(paymentList.value).forEach(item => {
                        sellerInfos.value.pay_type.map(Number).forEach(num => {
                            if(item.type == num) {
                                buyerPayArr.push(item)
                            }
                        })
                    })
                    buyerPayList.value = buyerPayArr
                    showBuyDialog.value = true
                } else {
                  Toast.fail(res.msg)
                }
            })
        }
        // 购买数量
        const buyAmountValue = ref('')
        // 只能输入整数跟2位小数
        const formatter = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
        // 点击支付，购买货币
        const handleDoBuy = (item) => {
            if (btnClock.value) return
            if(!buyAmountValue.value) {
                return Toast.fail('请输入购买数量')
            } else if(buyAmountValue.value < Number(sellerInfos.value.min_amount)) {
                return Toast.fail('购买数量不能小于最小数量')
            }
            btnClock.value = true
            let params = {
                id: sellerInfos.value.id,
                type: item.type,
                amount: buyAmountValue.value
            }
            doBuy(params).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    props.handleEmitsDoBuy(res.data.info)
                    buyAmountValue.value = ''
                    showBuyDialog.value = false
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 将手机号或邮箱中间部分替换为*
        const accountFilter = (account) => {
            if (account) {
                let start = account.slice(0,4);
                let end = account.slice(-4);
                return `${start}******${end}`;
            }
        }
        // 按钮锁
        const btnClock = ref(false)
        return {
            store,
            availableBalance,
            sellLists,
            themeImgs,
            openBuyCoin,
            showBuyDialog,
            sellerInfos,
            formatter,
            buyAmountValue,
            paymentList,
            handleDoBuy,
            accountFilter,
            btnClock,
            buyerPayList
        }
    }
}
</script>

<style lang="less" scoped>
.tradingHall-wrap {
    display: flex;
    margin-top: 13px;
    .main-left-box {
        display: flex;
        flex-direction: column;
        padding-left: 27px;
        padding-top: 10px;
        img {
            width: 84px;
            height: 32px;
            margin-bottom: 16px;
        }
    }
    .main-right-box {
        width: 697px;
        padding-left: 6px;
        .right-slide-box {
            width: 132px;
            height: 197px;
            padding: 11px;
            text-align: center;
            background: url('../assets/images/tradingHall/slide-bg.png') no-repeat;
            background-size: 100% 100%;
            .slide-item-top-box {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 38px;
                .slide-avatar {
                    width: 28px;
                    height: 28px;
                    margin-right: 5px;
                    border-radius: 50%;
                }
                .sell-amount {
                    color: #FFE597;
                    font-family: 'SF Pro Display';
                    font-weight: 600;
                }
            }
            .slide-pay-way-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 50px;
                img {
                    width: 22px;
                    height: 22px;
                }
            }
            .demolition-sale-text {
                line-height: 45px;
                font-family: 'PingFang SC';
                font-weight: 500;
                color: #fff;
                text-align: center;
            }
            .buy-btn {
                width: 99px;
                height: 38px;
            }
        }
        :deep(.van-empty) {
            padding: 0;
            .van-empty__description {
                margin-top: 0;
                font-family: 'PingFang SC';
                font-weight: 500;
                color: rgba(255, 255, 255, 0.9);
                text-shadow: 0px 1px 1px #305A9D;
            }
        }
    }
    :deep(.buycoin-dialog) {
        width: 506px;
        height: 331px;
        top: 50%;
        padding-top: 70px;
        background: url('../assets/images/tradingHall/buycoin-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .buycoin-main-box {
            height: 261px;
            padding: 11px 80px;
            overflow-y: auto;
            .van-cell {
                width: 100%;
                background: #0B1D4E;
                border-radius: 8px;
                .van-field__left-icon {
                    width: 32px;
                    height: 32px;
                    img {
                        width: 32px;
                        height: 32px;
                    }
                }
            }
            .buy-amount {
                margin-top: 10px;
                font-weight: 500;
                color: #FFD65A;
            }
            .buyer-info-wrap {
                margin-top: 16px;
                .item-box {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 15px;
                    .label {
                        font-weight: 500;
                    }
                    .right-box {
                        display: flex;
                        align-items: center;
                        .van-image {
                            width: 24px;
                            height: 24px;
                            margin-right: 12px;
                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }
                }
            }
            .payment-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 24px;
                .van-image {
                    width: 24px;
                    height: 24px;
                }
                span {
                    font-weight: 500;
                    font-size: 16px;
                }
                .pay-btn {
                    width: 64px;
                    height: 30px;
                    background: url('../assets/images/tradingHall/pay-btn.png') no-repeat;
                    background-size: 100% 100%;
                }
            }
        }
    }
}
</style>
