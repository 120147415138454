<template>
    <div class="transfer-wrap">
        <van-dialog class="transfer-dialog" v-model:show="showTransferDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseTransferDialog"></div>
            <div class="transfer-main-wrap">
                <van-cell-group inset class="transfer-form-wrap">
                    <van-field v-model="transferParams.currency" label="货币数量" center :border="false" clearable :formatter="formatterNumber" placeholder="请输入货币数量" autocomplete="off" />
                    <van-field v-model="transferParams.address" label="转账地址" center :border="false" clearable :formatter="formatter" placeholder="请输入钱包地址" autocomplete="off" />
                    <van-field v-model="transferParams.pay_password" type="password" label="支付密码" center :border="false" clearable :formatter="formatter" placeholder="请输入支付密码" autocomplete="off" />
                    <van-field label="钱包类型" center :border="false">
                        <template #input>
                            <div class="select-type-box" @click="showTypeActions=true">
                                <span>{{curTypeName}}</span>
                                <van-image :src="require('../../assets/images/common/down-arrow-icon.png')" />
                            </div>
                        </template>
                    </van-field>
                </van-cell-group>
                <div class="comfirm-btn-box">
                    <van-button block :disabled="btnClock" @click="handleComfirmTransfer">确认转账</van-button>
                </div>
            </div>
        </van-dialog>
        <!-- 钱包类型 -->
        <van-action-sheet
            v-model:show="showTypeActions"
            :actions="typeActions"
            description="选择钱包类型"
            cancel-text="取消"
            close-on-click-action
            @cancel="showTypeActions=false"
            @select="handleSelectActions"
        />
    </div>
</template>

<script>
import { ref, reactive,computed } from 'vue'
import { doTransfer } from '@/api/api'
import { Toast } from 'vant'
import { useStore } from "vuex";

export default {
    props:['handleCloseTransferDialog'],
    setup () {
        const store = useStore()
        // 转账参数
        const transferParams = reactive({
            currency: '',
            address: '',
            pay_password: '',
            type: '',
        })
        // 过滤输入的中文
        const formatter = (value) => value.replace(/[\u4e00-\u9fa5]/g, "");
        // 只能输入整数跟2位小数
        const formatterNumber = (value) => value.match(/^\d*(\.?\d{0,2})/g)[0]
        // 当前钱包类型type
        const curTypeName = ref('请选择钱包类型')
        // 是否显示钱包类型动作面板
        const showTypeActions = ref(false)
        // 钱包类型actions
        const typeActions = [
            { name: '商户',type:3 },
            { name: '用户',type:1 },
            { name: '代理',type:2 }
        ]
        // 选择交易类型
        const handleSelectActions = action => {
            curTypeName.value = action.name
            transferParams.type = action.type
        }
        // 确认转账
        const handleComfirmTransfer = () => {
            if (btnClock.value) return
            if(!transferParams.currency) {
                return Toast.fail('请输入货币数量')
            } else if(!transferParams.address) {
                return Toast.fail('请输入钱包地址')
            } else if(!transferParams.pay_password) {
                return Toast.fail('请输入支付密码')
            } else if(curTypeName.value=='请选择钱包类型') {
                return Toast.fail('请选择钱包类型')
            }
            btnClock.value = true
            doTransfer(transferParams).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    Toast.success('转账成功')
                    transferParams.currency = ''
                    transferParams.address = ''
                    transferParams.pay_password = ''
                    transferParams.type = ''
                    curTypeName.value='请选择钱包类型'
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示修改登录密码弹框
        const showTransferDialog = ref(true)
        return {
            transferParams,
            formatter,
            formatterNumber,
            curTypeName,
            showTypeActions,
            typeActions,
            handleSelectActions,
            handleComfirmTransfer,
            btnClock,
            themeImgs,
            showTransferDialog
        }
    }
}
</script>

<style lang="less" scoped>
.transfer-wrap {
    :deep(.transfer-dialog) {
        width: 446px;
        height: 301px;
        top: 50%;
        padding-top: 55px;
        background: url('../../assets/images/my/transfer-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 20px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .transfer-main-wrap {
            padding: 20px 50px 0;
            .transfer-form-wrap {
                background: #2B4F87;
                border-radius: 8px;
                .van-cell {
                    background: none;
                    .select-type-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 184px;
                        height: 38px;
                        background: rgba(255, 255, 255, 0.5);
                        border: 1px solid #02318B;
                        border-radius: 30px;
                        .van-image {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .comfirm-btn-box {
                margin-top: 20px;
                padding: 0 16px;
                border-radius: 8px;
                .van-button {
                    width: 216px;
                    height: 41px;
                    margin: auto;
                    background: url('../../assets/images/common/btn-bg.png') no-repeat;
                    background-size: 100% 100%;
                    border-radius: 8px;
                }
            }
        }
    }
}
</style>