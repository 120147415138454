<template>
    <div class="orderDetails-wrap">
        <van-dialog class="orderDetails-dialog" v-model:show="showSellerOrderDetailsDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseSellerOrderDetailsDialog"></div>
            <div class="orderDetails-main-wrap">
                <div class="progress-wrap">
                    <div class="step-item-box" :class="{'active': orderDetails.status >= 1}">
                        <div class="step-icon order-icon" :style="`background:url(${orderDetails.status >= 1 ? require('../../assets/images/order/process_active_icon.png') : require('../../assets/images/order/process_icon.png')})`"></div>
                        <span>发起订单</span>
                    </div>
                    <div class="step-item-box" :class="{'active': orderDetails.status >= 2}">
                        <div class="step-icon order-icon" :style="`background:url(${orderDetails.status >= 2 ? require('../../assets/images/order/process_active_icon.png') : require('../../assets/images/order/process_icon.png')})`"></div>
                        <span>卖家确认</span>
                    </div>
                    <div class="step-item-box" :class="{'active': orderDetails.status >= 3}">
                        <div class="step-icon order-icon" :style="`background:url(${orderDetails.status >= 3 ? require('../../assets/images/order/process_active_icon.png') : require('../../assets/images/order/process_icon.png')})`"></div>
                        <span>买家已转账</span>
                    </div>
                    <div class="step-item-box" :class="{'active': orderDetails.status == 5 || orderDetails.status > 5}">
                        <div class="step-icon order-icon" :style="`background:url(${(orderDetails.status == 5 || orderDetails.status > 5) ? require('../../assets/images/order/process_active_icon.png') : require('../../assets/images/order/process_icon.png')})`"></div>
                        <span v-if="orderDetails.status != 10">卖家已打币</span>
                        <span v-if="orderDetails.status == 10">卖家已暂停</span>
                    </div>
                </div>
                <div class="order-detail-wrap">
                    <div class="top-wrap">
                        <div class="item-box">
                            <span>订单编号：{{orderDetails.order_sn}}</span>
                            <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.order_sn)" />
                        </div>
                    </div>
                    <!-- 卖家发起订单 -->
                    <div class="main-detail-box" v-if="orderDetails.status == 1">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">买家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">买家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">购买数量</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">创建时间</span>
                                <div class="right-box">
                                    <span>{{orderDetails.created_at}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">付款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_pay_type == 1 ? '支付宝' : orderDetails.buy_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_card)" />
                                </div>
                            </div>
                        </div>
                        <div class="btn-box">
                            <div class="btn cancel-btn" @click="showRemarkDialog=true">撤销</div>
                            <div class="btn comfirm-btn" @click="handleComfirmSellerOrder">确认</div>
                        </div>
                    </div>
                    <!-- 卖家确认订单后 -->
                    <div class="main-detail-box" v-if="orderDetails.status == 2">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">买家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type != 3">
                                <span class="label">买家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">付款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_pay_type == 1 ? '支付宝' : orderDetails.buy_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_card)" />
                                </div>
                            </div>
                        </div>
                        <p class="timer">等待买家付款</p>
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">卖家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type != 3">
                                <span class="label">卖家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">收款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_pay_type == 1 ? '支付宝' : orderDetails.sell_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_card)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 买家已转账并且上传凭证后 -->
                    <div class="main-detail-box" v-if="orderDetails.status == 3 || orderDetails.status == 4">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">买家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type != 3">
                                <span class="label">买家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">付款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_pay_type == 1 ? '支付宝' : orderDetails.buy_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_card)" />
                                </div>
                            </div>
                        </div>
                        <p class="timer">买家已付款</p>
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">卖家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type != 3">
                                <span class="label">卖家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">收款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_pay_type == 1 ? '支付宝' : orderDetails.sell_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_card)" />
                                </div>
                            </div>
                        </div>
                        <div class="view-voucher-btn" @click="showPaymentVoucherDialog=true">查看凭证</div>
                        <div class="btn-box">
                            <div class="cancel-btn btn" @click="handlePauseSellerPay">暂停打币</div>
                            <div class="comfirm-btn btn" @click="showPayDialog = true">确认打币</div>
                        </div>
                    </div>
                    <!-- 卖家已打币 -->
                    <div class="main-detail-box" v-if="orderDetails.status == 5 || orderDetails.status == 9 || orderDetails.status==13">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">买家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type != 3">
                                <span class="label">买家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">付款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_pay_type == 1 ? '支付宝' : orderDetails.buy_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_card)" />
                                </div>
                            </div>
                        </div>
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">卖家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type != 3">
                                <span class="label">卖家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">收款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_pay_type == 1 ? '支付宝' : orderDetails.sell_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_card)" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 订单已取消 -->
                    <div class="main-detail-box" v-if="(orderDetails.status>5 && orderDetails.status<9) || orderDetails.status==12">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">创建时间</span>
                                <div class="right-box">
                                    <span>{{orderDetails.created_at}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">取消时间</span>
                                <div class="right-box">
                                    <span>{{orderDetails.updated_at}}</span>
                                </div>
                            </div>
                            <div class="voucher">订单已取消</div>
                        </div>
                        <div class="detail-box" v-if="orderDetails.content">
                            <div class="detail-item-box">
                                <span class="label">撤单备注</span>
                                <div class="right-box">
                                    <span>{{orderDetails.content}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 系统冻结 -->
                    <div class="main-detail-box" v-if="orderDetails.status === 14">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">创建时间</span>
                                <div class="right-box">
                                    <span>{{orderDetails.created_at}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">冻结时间</span>
                                <div class="right-box">
                                    <span>{{orderDetails.updated_at}}</span>
                                </div>
                            </div>
                            <div class="voucher">订单已冻结</div>
                        </div>
                    </div>
                    <!-- 卖家暂停打币 -->
                    <div class="main-detail-box" v-if="orderDetails.status == 10">
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">买家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type != 3">
                                <span class="label">买家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">付款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_pay_type == 1 ? '支付宝' : orderDetails.buy_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.buy_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.buy_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.buy_card)" />
                                </div>
                            </div>
                        </div>
                        <p class="timer">买家已付款</p>
                        <div class="detail-box">
                            <div class="detail-item-box">
                                <span class="label">卖家名字</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_name}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_name)" />
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type != 3">
                                <span class="label">卖家昵称</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_nick_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">金额</span>
                                <div class="right-box">
                                    <span>{{orderDetails.currency}} {{store.state.publicConfig.title}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box">
                                <span class="label">收款方式</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_pay_type == 1 ? '支付宝' : orderDetails.sell_pay_type == 2 ? '微信' : '银行卡'}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">开户行</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_bank_name}}</span>
                                </div>
                            </div>
                            <div class="detail-item-box" v-if="orderDetails.sell_pay_type == 3">
                                <span class="label">银行卡号</span>
                                <div class="right-box">
                                    <span>{{orderDetails.sell_card}}</span>
                                    <van-image :src="require('../../assets/images/order/order-copy-icon.png')" @click="handleCopy(orderDetails.sell_card)" />
                                </div>
                            </div>
                        </div>
                        <div class="view-voucher-btn" @click="showPaymentVoucherDialog=true">查看凭证</div>
                        <div class="btn-box">
                            <a class="cancel-btn btn" :href="serviceUrl" target="_blank">联系客服</a>
                            <div class="comfirm-btn btn" @click="showPayDialog = true">确认打币</div>
                        </div>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 付款凭证弹框 -->
        <van-dialog class="code-dialog" v-model:show="showPaymentVoucherDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showPaymentVoucherDialog=false"></div>
            <div class="code-box">
                <van-image :src="orderDetails.pay_pic" @click="openImagePreview(orderDetails.pay_pic)" />
                <span class="name">{{orderDetails.buy_name}}</span>
            </div>
        </van-dialog>
        <!-- 撤单备注弹框 -->
        <van-dialog class="remark-dialog" v-model:show="showRemarkDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showRemarkDialog=false"></div>
            <div class="content-box">
                <van-field
                    class="cancel-order-dialog-cell"
                    v-model="remarkContent"
                    rows="1"
                    autosize
                    label="撤单原因"
                    type="textarea"
                    placeholder="撤单原因可为空"
                    maxlength="50"
                    show-word-limit
                />
            </div>
            <div class="comfirm-btn-box">
                <van-button @click="handleCancelSellerOrder">确认</van-button>
            </div>
        </van-dialog>
        <!-- 支付密码弹出框 -->
        <van-dialog class="pay-password-dialog" v-model:show="showPayPasswordDialog" :show-confirm-button="false">
            <div class="close-btn" @click="onBeforeClose('close')"></div>
            <van-field
                class="cancel-order-dialog-cell"
                v-model="pay_password"
                type="password"
                label="支付密码"
                placeholder="请输入支付密码"
                maxlength="6"
            />
            <van-button class="pay-btn" block @click="onBeforeClose('confirm')">确定</van-button>
        </van-dialog>
      <!-- 温馨提示 -->
      <van-dialog class="remark-dialog" v-model:show="showPayDialog" :show-confirm-button="false">
        <div class="close-btn" @click="onPayBeforeClose('close')"></div>
        <div class="content-box">请核实资金到账后再进行确认打币，切勿未收到转账直接打币造成损失。</div>
        <div class="comfirm-btn-box">
          <van-button  block @click="onPayBeforeClose('confirm')">确定</van-button>
        </div>
      </van-dialog>
    </div>
</template>

<script>
import { ref,watchEffect,computed } from 'vue'
import { getSellerOrderDetails,comfirmSellerOrder,cancelSellerOrder,comfirmSellerPay,pauseSellerPay } from '@/api/api'
import { Toast, Step, Steps, ImagePreview } from 'vant'
import { useRoute,onBeforeRouteLeave } from 'vue-router'
import useClipboard from 'vue-clipboard3'
import { useStore } from 'vuex'

export default {
    components: {
        [Step.name]: Step,
        [Steps.name]: Steps
    },
    props: ['handleCloseSellerOrderDetailsDialog', 'orderSn', 'handleGetUntreatedRemind'],
    setup (props) {
        const $route = useRoute()
        const store = useStore()
        const { toClipboard } = useClipboard()
        // 定义在线客服地址
        const serviceUrl = ref(store.state.sysConfig.manage)
        // 获取订单详情
        const handleGetOrderDetails = () => {
            getSellerOrderDetails({order_sn:props.orderSn}).then(res=> {
                if(res.result == 200000) {
                    orderDetails.value = res.data.list
                    // status：1发起订单 2卖家确认 3买家已转账 4买家上传凭证 5卖家打币 6卖家取消 7买家取消 8系统取消 9系统自动打币 10卖家暂停打币
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 当路由参数变化时，页面数据无法更新的时候
        watchEffect(()=>{
            handleGetOrderDetails()
        })
        // 存储订单信息
        const orderDetails = ref({})
        // 复制
        const handleCopy = async(content) => {
            try {
                await toClipboard(content)
                Toast.success('复制成功')
            } catch (e) {
                console.error(e)
            }
        }
        // 当前进度索引值
        const stepActiveIndex = ref(1)
        // 卖家确认订单
        const handleComfirmSellerOrder = () => {
            comfirmSellerOrder({order_sn: orderDetails.value.order_sn}).then(res => {
                if(res.result == 200000) {
                  store.commit('setUntreatedNum', 0)
                  handleGetOrderDetails()
                } else {
                    Toast.fail(res.msg)
                    handleGetOrderDetails()
                }
            })
        }
        // 是否显示撤单备注弹框
        const showRemarkDialog = ref(false)
        // 存储撤单备注内容
        const remarkContent = ref('')
        // 卖家取消订单
        const handleCancelSellerOrder = () => {
            cancelSellerOrder({order_sn: orderDetails.value.order_sn,content:remarkContent.value}).then(res => {
                if(res.result == 200000) {
                    Toast.success('撤单成功')
                    showRemarkDialog.value = false
                    handleGetOrderDetails()
                    props.handleGetUntreatedRemind()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 是否显示付款凭证弹框
        const showPaymentVoucherDialog = ref(false)
        // 卖家暂停打币
        const handlePauseSellerPay = () => {
            pauseSellerPay({order_sn: orderDetails.value.order_sn}).then(res=>{
                if(res.result == 200000) {
                    Toast.success('暂停打币成功')
                    handleGetOrderDetails()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        // 是否显示支付密码弹框
        const showPayPasswordDialog = ref(false)
        // 支付密码变量
        const pay_password = ref('')
        // 取消默认关闭弹框事件
        const onBeforeClose = (action) => {
            // 点击了确定按钮
            if (action === "confirm") {
                if (!pay_password.value) {
                    Toast.fail('请输入支付密码')
                    return false
                } else {
                  store.commit('setUntreatedNum', 0)
                  handleComfirmSellerPay()
                }
            }
            // 点击了取消按钮
            else {
                showPayPasswordDialog.value = false
                pay_password.value = ''
            }
        }
        // 卖家确认打币
        const handleComfirmSellerPay = () => {
            comfirmSellerPay({order_sn: orderDetails.value.order_sn, pay_password: pay_password.value}).then(res=>{
                if(res.result == 200000) {
                    showPayPasswordDialog.value = false
                    pay_password.value = ''
                    Toast.success('打币成功')
                    handleGetOrderDetails()
                } else {
                    Toast.fail(res.msg)
                }
            })
        }
        onBeforeRouteLeave((to, from, next) => {
            if(to.path == "/buyCoin") {
                next('/home')
            } else {
                next()
            }
        })
        // 温馨提示
        const showPayDialog = ref(false)
        // 取消默认关闭弹框事件
        const onPayBeforeClose = (action) => {
          // 点击了确定按钮
          if (action === "confirm") {
            showPayDialog.value = false
            showPayPasswordDialog.value = true
          }
          // 点击了取消按钮
          else {
            showPayDialog.value = false
          }
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 显示买家订单详情弹框
        const showSellerOrderDetailsDialog = ref(true)
        // 图片预览
        const openImagePreview = (url) => {
            ImagePreview({
                images: [url],
                closeable: true,
            })
        }
        return {
            orderDetails,
            stepActiveIndex,
            handleCopy,
            showPaymentVoucherDialog,
            handleComfirmSellerOrder,
            handleCancelSellerOrder,
            handleComfirmSellerPay,
            handlePauseSellerPay,
            serviceUrl,
            showRemarkDialog,
            remarkContent,
            themeImgs,
            showPayPasswordDialog,
            showPayDialog,
            pay_password,
            onBeforeClose,
            onPayBeforeClose,
            store,
            showSellerOrderDetailsDialog,
            openImagePreview
        }
    }
}
</script>

<style lang="less" scoped>
.orderDetails-wrap {
    :deep(.orderDetails-dialog) {
        width: 445px;
        height: 336px;
        top: 50%;
        padding-top: 58px;
        background: url('../../assets/images/order/order-detail-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
            z-index: 1;
        }
        .orderDetails-main-wrap {
            height: 275px;
            padding: 5px 49px 10px;
            overflow: scroll;
            .progress-wrap {
                display: flex;
                align-items: center;
                justify-content: space-between;
                height: 68px;
                padding: 0 41px;
                background: #0B1D4E;
                border-radius: 8px;
                :deep(.van-steps) {
                    background: inherit;
                    border-radius: 8px;
                    .van-step__circle-container {
                        background: inherit;
                        .van-image {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
                .step-item-box {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .step-icon {
                        position: relative;
                        width: 24px;
                        height: 24px;
                        margin-bottom: 6px;
                        background-size: 100% 100% !important;
                    }
                    span {
                        font-size: 10px;
                        font-weight: 500;
                    }
                    &:not(:last-child) {
                        .step-icon {
                            &:after {
                                position: absolute;
                                content: '';
                                width: 50px;
                                height: 0;
                                border: 1px solid #D2D2D2;
                                top: 12px;
                                left: 24px;
                            }
                        }
                    }
                    &.active {
                        .step-icon {
                            &:after {
                                border: 1px solid #FFD65A;
                            }
                        }
                        span {
                            color: #FFD65A;
                        }
                    }
                }
            }
            .order-detail-wrap {
                margin-top: 20px;
                .top-wrap {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    margin-bottom: 6px;
                    font-size: 12px;
                    color: #FFD65A;
                    .item-box {
                        display: flex;
                        align-items: center;
                        span {
                            font-weight: 500;
                            margin-right: 7px;
                            color: #FFD65A;
                        }
                        .van-image {
                            width: 16px;
                            height: 16px;
                        }
                    }
                }
                .main-detail-box {
                    .detail-box {
                        margin-bottom: 10px;
                        padding: 14px 10px;
                        background: #0B1D4E;
                        border-radius: 8px;
                        .detail-item-box {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin-bottom: 10px;
                            color: var(--textCommonColor);
                            &:last-child {
                                margin-bottom: 0;
                            }
                            .label {
                                flex: 0 0 30%;
                                font-size: 16px;
                                font-weight: 500;
                            }
                            .right-box {
                                display: flex;
                                align-items: center;
                                span {
                                    font-size: 16px;
                                }
                                .van-image {
                                    width: 16px;
                                    height: 16px;
                                    margin-left: 6px;
                                }
                            }
                        }
                        .voucher {
                            font-weight: 500;
                            font-size: 18px;
                            color: #FFD65A;
                            text-align: center;
                        }
                    }
                    .timer {
                        display: flex;
                        align-items: center;
                        margin-bottom: 10px;
                        font-size: 12px;
                        font-weight: 500;
                        color: #FFD65A;
                        .van-count-down {
                            line-height: unset;
                            color: #FFD65A;
                            font-size: 12px;
                            font-weight: 500;
                        }
                    }
                    .btn-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;
                        margin-top: 10px;
                        padding: 0 58px;
                        .btn {
                            width: 118px;
                            height: 40px;
                            line-height: 40px;
                            border-radius: 8px;
                            text-align: center;
                            font-weight: 500;
                            font-size: 16px;
                        }
                        .cancel-btn {
                            margin-right: 16px;
                            background: url('../../assets/images/common/small-cancel-btn-bg.png') no-repeat;
                            background-size: 100% 100%;
                        }
                        .comfirm-btn {
                            background: url('../../assets/images/common/small-btn-bg.png') no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .view-voucher-btn {
                        width: 100%;
                        padding: 10px 0;
                        margin-top: 10px;
                        text-align: center;
                        border-radius: 8px;
                        color: #FFD65A;
                        font-weight: 500;
                        font-size: 14px;
                    }
                    .tip {
                        margin-top: 10px;
                        margin-bottom: 30px;
                        text-align: center;
                        line-height: 20px;
                        color: var(--textCommonColor);
                    }
                }

            }
        }
    }
    :deep(.code-dialog) {
        width: 443px;
        height: 287px;
        top: 50%;
        padding-top: 45px;
        background: url('../../assets/images/order/paymentVoucher-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .code-box {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            height: 242px;
            .van-image {
                width: 158px;
                height: 158px;
                background: #C4C4C4;
                border-radius: 8px;
                .van-image__error {
                    border-radius: 8px;
                }
            }
            .name {
                margin-top: 20px;
            }
        }
    }
    :deep(.pay-password-dialog) {
        width: 320px;
        height: 203px;
        top: 50%;
        padding: 70px 40px 0;
        background: url('../../assets/images/sellcoin/pay-pwd-bg.png') no-repeat;
        background-size: 100% 100%;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .van-cell {
            width: 100%;
            background: #293966;
            border-radius: 8px;
        }
        .pay-btn {
            height: 31px;
            margin: 57px auto 0;
            background: url('../../assets/images/common/btn-bg.png') no-repeat;
            background-size: 100% 100%;
            border-radius: 8px;
        }
    }
    :deep(.remark-dialog) {
        width: 320px;
        height: 203px;
        top: 50%;
        padding: 42px 20px 0;
        background: url('../../assets/images/common/tips-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 15px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .content-box {
            display: flex;
            align-items: center;
            height: 107px;
            .van-cell {
                width: 100%;
                background: #293966;
                border-radius: 8px;
            }
        }
        .comfirm-btn-box {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 55px;
            .van-button {
                width: 216px;
                height: 34px;
                margin: auto;
                background: url('../../assets/images/common/btn-bg.png') no-repeat;
                background-size: 100% 100%;
                border-radius: 8px;
            }
        }
    }
}
</style>
