<template>
    <div class="payment-wrap">
        <van-dialog class="payment-dialog" v-model:show="showPaymentDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleClosePaymentDialog"></div>
            <div class="payment-loading-wrap" v-if="showLoading">
                <van-loading size="48" color="#fff" vertical>加载中...</van-loading>
            </div>
            <div class="payment-main-wrap" v-else>
                <div class="add-payment-box" v-if="JSON.stringify(paymentList) == '{}'" @click="showAddPaymentDialog=true">
                    <van-image :src="require('../../assets/images/payment/add.png')" />
                    <span>添加收付款方式</span>
                </div>
                <div class="payment-item-wrap" v-else>
                    <div class="payment-item-box-wrap" v-for="(item,index) in paymentList" :key="index">
                        <div class="payment-item-box" v-if="item.type == 1">
                            <van-image class="type-img" :src="require('../../assets/images/common/payment_icon1.png')" />
                            <div class="payment-detail-wrap">
                                <div class="payment-detail-box">
                                    <span class="label">支付宝名：</span>
                                    <span class="text">{{item.name}}</span>
                                </div>
                                <div class="payment-detail-box">
                                    <span class="label">支付宝账号：</span>
                                    <span class="text">{{item.card}}</span>
                                </div>
                                <div class="payment-detail-box">
                                    <span class="label">查看二维码：</span>
                                    <span class="text" @click="handleOpenCodeDialog(item)">查看二维码<van-image class="type-img" :src="require('../../assets/images/payment/right-arrow-icon.png')" /></span>
                                </div>
                            </div>
                            <div class="payment-status disabled" v-if="item.status==2"></div>
                            <div class="payment-status sys-disabled" v-if="item.status==3"></div>
                        </div>
                        <div class="payment-item-box" v-if="item.type == 2">
                            <van-image class="type-img" :src="require('../../assets/images/common/payment_icon2.png')" />
                            <div class="payment-detail-wrap">
                                <div class="payment-detail-box">
                                    <span class="label">微信名：</span>
                                    <span class="text">{{item.name}}</span>
                                </div>
                                <div class="payment-detail-box">
                                    <span class="label">查看二维码：</span>
                                    <span class="text" @click="handleOpenCodeDialog(item)">查看二维码<van-image class="type-img" :src="require('../../assets/images/payment/right-arrow-icon.png')" /></span>
                                </div>
                            </div>
                            <div class="payment-status disabled" v-if="item.status==2"></div>
                            <div class="payment-status sys-disabled" v-if="item.status==3"></div>
                        </div>
                        <div class="payment-item-box" v-if="item.type == 3">
                            <van-image class="type-img" :src="require('../../assets/images/common/payment_icon3.png')" />
                            <div class="payment-detail-wrap">
                                <div class="payment-detail-box">
                                    <span class="label">银行卡号：</span>
                                    <span class="text">{{item.card}}</span>
                                </div>
                                <div class="payment-detail-box">
                                    <span class="label">银行名：</span>
                                    <span class="text">{{item.bank_name}}</span>
                                </div>
                            </div>
                            <div class="payment-status disabled" v-if="item.status==2"></div>
                            <div class="payment-status sys-disabled" v-if="item.status==3"></div>
                        </div>
                    </div>
                    <div class="add-btn-box" v-if="Object.values(paymentList).length<3">
                        <van-button block @click="showAddPaymentDialog=true">
                            <span>添加收付款方式</span>
                            <van-image :src="require('../../assets/images/payment/more-icon.png')" />
                        </van-button>
                    </div>
                </div>
            </div>
        </van-dialog>
        <!-- 添加支付方式弹框 -->
        <AddPayment v-if="showAddPaymentDialog" :handleCloseAddPaymentDialog="handleCloseAddPaymentDialog" />
        <!-- 二维码弹框 -->
        <van-dialog class="show-code-dialog" v-model:show="showCodeDialog" :show-confirm-button="false">
            <div class="close-btn" @click="showCodeDialog=false"></div>
            <div class="code-box">
                <van-image :src="dialogCodeImg" />
            </div>
        </van-dialog>
    </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue'
import AddPayment from "./AddPayment.vue"
import {getPaymentList} from '@/api/api'
import { useRouter } from "vue-router"
import { Toast } from 'vant'
import { useStore } from 'vuex'

export default {
    props:['handleClosePaymentDialog'],
    components: {
        AddPayment
    },
    setup () {
        const store = useStore()
        const $router = useRouter()
        // 是否显示弹框
        const showPaymentDialog = ref(true)
        // 是否显示加载
        const showLoading = ref(true)
        // 存储收付款列表数据
        const paymentList = ref({})
        // 获取收付款列表数据
        const handleGetPaymentList = () => {
            getPaymentList().then(res => {
                if(res.result == 1032) {
                    handleClosePaymentDialog()
                    return Toast.fail(res.msg)
                } else if(res.result == 200000) {
                    paymentList.value = res.data
                    showLoading.value = false
                } else {
                    showLoading.value = false
                }
            })
        }
        onMounted(() => {
            handleGetPaymentList()
        })
        // 是否显示二维码弹框
        const showCodeDialog = ref(false)
        // 存储二维码弹框所要展示的二维码
        const dialogCodeImg = ref('')
        // 打开二维码弹框
        const handleOpenCodeDialog = (item) => {
            showCodeDialog.value = true
            dialogCodeImg.value = item.bank_name
        }
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        // 打开新增收付款弹框
        const showAddPaymentDialog = ref(false)
        // 关闭新增收付款弹框
        const handleCloseAddPaymentDialog = (refresh) => {
            if (refresh) {
                handleGetPaymentList()
            }
            showAddPaymentDialog.value = false
        }
        return {
            showPaymentDialog,
            paymentList,
            showLoading,
            showCodeDialog,
            dialogCodeImg,
            handleOpenCodeDialog,
            themeImgs,
            showAddPaymentDialog,
            handleCloseAddPaymentDialog
        }
    }
}
</script>

<style lang="less" scoped>
.payment-wrap {
    :deep(.payment-dialog) {
        width: 508px;
        height: 331px;
        top: 50%;
        padding-top: 70px;
        background: url('../../assets/images/payment/payment-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 32px;
            height: 32px;
        }
        .payment-loading-wrap {
            position: absolute;
            left: 50%;
            top: 60%;
            transform: translate(-50%, -50%);
        }
        .payment-main-wrap {
            padding: 0 80px 20px;
            height: 261px;
            overflow-y: scroll;
            .add-payment-box {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                height: 143px;
                margin-top: 55px;
                background: #2B4F87;
                border-radius: 8px;
                .van-image {
                    width: 72px;
                    height: 72px;
                    margin-bottom: 10px;
                }
                span {
                    font-weight: 500;
                    font-size: 18px;
                    color: #fff;
                }
            }
            .payment-item-wrap {
                padding-top: 15px;
                .payment-item-box-wrap {
                    background: #2B4F87;
                    border-radius: 8px;
                    .payment-item-box {
                        position: relative;
                        display: flex;
                        align-items: center;
                        margin-bottom: 12px;
                        padding: 12px 18px;
                        background: var(--boxBgColor);
                        border-radius: 8px;
                        .type-img {
                            width: 24px;
                            height: 24px;
                        }
                        .payment-detail-wrap {
                            margin-left: 14px;
                            .payment-detail-box {
                                display: flex;
                                align-items: center;
                                margin-bottom: 14px;
                                &:last-child {
                                    margin-bottom: 0;
                                }
                                span {
                                    display: flex;
                                    align-items: center;
                                    font-weight: 500;
                                    &.text {
                                        color: #FFD65A;
                                    }
                                    .van-image {
                                        width: 16px;
                                        height: 16px;
                                    }
                                }
                                
                            }
                        }
                        .payment-status {
                            position: absolute;
                            top: 11px;
                            right: 18px;
                            width: 44px;
                            height: 19px;
                            &.disabled {
                                background: url('../../assets/images/payment/disabled-status-img.png') no-repeat;
                                background-size: 100% 100%;
                            }
                            &.sys-disabled {
                                background: url('../../assets/images/payment/sys-disabled-status-img.png') no-repeat;
                                background-size: 100% 100%;
                            }
                        }
                    }
                }
                .add-btn-box {
                    .van-button {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 216px;
                        height: 31px;
                        margin: auto;
                        background: url('../../assets/images/common/btn-bg.png') no-repeat;
                        background-size: 100% 100%;
                        border-radius: 8px;
                        .van-button__text {
                            display: flex;
                            align-items: center;
                            span {
                                margin-right: 9px;
                                text-shadow: 0px 1px 1px #305A9D;
                            }
                            .van-image {
                                width: 12px;
                                height: 12px;
                            }
                        }
                        
                    }
                }
            }
        }
    }
    :deep(.show-code-dialog) {
        width: 445px;
        height: 291px;
        top: 50%;
        padding-top: 44px;
        background: url('../../assets/images/payment/look-code-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 14px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .van-dialog__content {
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
            .code-box {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 158px;
                height: 158px;
                background: #C4C4C4;
                border-radius: 8px;
                .van-image {
                    width: 100%;
                    height: 100%;
                    border-radius: 8px;
                    .van-image__error {
                        border-radius: 8px;
                    }
                    .van-image__loading {
                        border-radius: 8px;
                    }
                }
            }
        }
    }
}
</style>