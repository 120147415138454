<template>
    <div class="addPayment-wrap">
        <van-dialog class="addPayment-dialog" v-model:show="showAddPaymentDialog" :show-confirm-button="false">
            <div class="close-btn" @click="handleCloseAddPaymentDialog"></div>
            <div class="addPayment-main-wrap">
                <h3>选择收付款方式</h3>
                <div class="addPayment-type-box">
                    <div class="item-box" :class="{'active' : paymentWayIndex == 1}" @click="handleChangeWay(1)">
                        <van-image :src="require('../../assets/images/common/payment_icon1.png')" />
                        <span>支付宝</span>
                    </div>
                    <div class="item-box" :class="{'active' : paymentWayIndex == 2}" @click="handleChangeWay(2)">
                        <van-image :src="require('../../assets/images/common/payment_icon2.png')" />
                        <span>微信</span>
                    </div>
                    <div class="item-box" :class="{'active' : paymentWayIndex == 3}" @click="handleChangeWay(3)">
                        <van-image :src="require('../../assets/images/common/payment_icon3.png')" />
                        <span>银行卡</span>
                    </div>
                </div>
                <h3 class="fill-title">填充信息</h3>
                <div class="alipay-info-wrap common-info-wrap" v-if="paymentWayIndex == 1">
                    <div class="info-item">
                        <span class="label">姓名</span>
                        <van-field v-model="realname" :readonly="isFromComprehensive" placeholder="请输入真实姓名" autocomplete="off" />
                    </div>
                    <div class="info-item">
                        <span class="label">支付宝账号</span>
                        <van-field v-model="alipayAccount" placeholder="请输入支付宝账号" autocomplete="off" />
                    </div>
                    <div class="info-item">
                        <span class="label">上传收款码</span>
                        <van-uploader v-model="codeFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUpload" />
                    </div>
                </div>
                <div class="wechat-info-wrap common-info-wrap" v-if="paymentWayIndex == 2">
                    <div class="info-item">
                        <span class="label">姓名</span>
                        <van-field v-model="realname" :readonly="isFromComprehensive" placeholder="请输入真实姓名" autocomplete="off" />
                    </div>
                    <div class="info-item">
                        <span class="label">上传收款码</span>
                        <van-uploader v-model="codeFileList" :max-count="1" :before-read="beforeUpload" :after-read="afterUpload" />
                    </div>
                </div>
                <div class="bank-info-wrap common-info-wrap" v-if="paymentWayIndex == 3">
                    <div class="info-item">
                        <span class="label">姓名</span>
                        <van-field v-model="realname" :readonly="isFromComprehensive" placeholder="请输入真实姓名" autocomplete="off" />
                    </div>
                    <div class="info-item">
                        <span class="label">银行卡号</span>
                        <van-field v-model="bankCard" placeholder="请输入银行卡卡号" maxlength="19" autocomplete="off" />
                    </div>
                    <div class="info-item">
                        <span class="label">选择银行</span>
                        <div class="bank-box" @click="showBankAction=true">
                            <span>{{bankName.name}}</span>
                            <van-image :src="require('../../assets/images/payment/down-arrow-icon.png')" />
                        </div>
                    </div>
                </div>
                <div class="add-btn-box">
                    <van-button block :disabled="btnClock" @click="handleAdd">添加</van-button>
                </div>
            </div>
        </van-dialog>
        <van-action-sheet
            v-model:show="showBankAction"
            :actions="bankActions"
            cancel-text="取消"
            description="选择银行卡"
            close-on-click-action
            @select="handleSelectBankAction"
        />
        <van-overlay class-name="addPayment-overlay" :show="showOverlay">
            <van-loading size="24px" type="spinner" color="#fff" vertical>上传中...</van-loading>
        </van-overlay>
    </div>
</template>

<script>
import { ref,onMounted,computed } from 'vue'
import {getBankList,doAddPayment,uploadImage} from '@/api/api'
import { Toast } from 'vant'
import { useStore } from 'vuex'

export default {
    props:['handleCloseAddPaymentDialog'],
    setup (props) {
        const store = useStore()
        // 是否显示弹框
        const showAddPaymentDialog = ref(true)
        // 默认付款方式索引值
        const paymentWayIndex = ref(1)
        // 选择收付款方式,并重置所有
        const handleChangeWay = (index) => {
            paymentWayIndex.value = index
            if(!window.parent.frames['agIframe']) {
                realname.value = ''
            }
            alipayAccount.value = ''
            codeFileList.value = []
            bankCard.value = ''
        }
        // 是否显示银行卡actions
        const showBankAction = ref(false)
        // 银行卡actions
        const bankActions = ref([]);
        // 获取银行列表数据
        const handleGetBankList = () => {
            getBankList().then(res => {
                if(res.result == 200000) {
                    Object.getOwnPropertyNames(res.data).forEach(function(key){
                        let obj = {}
                        obj.name = res.data[key]
                        obj.id = key
                        bankActions.value.push(obj)
                    })
                }
            })
        }
        onMounted(() => {
            handleGetBankList()
            handleJudgeFromComprehensive()
        })
        // 判断是否从综合那接收真实姓名
        const isFromComprehensive = ref(false)
        const handleJudgeFromComprehensive = () => {
            if(window.parent.frames['agIframe']) {
                if (store.state.actualName) {
                    realname.value = store.state.actualName
                    isFromComprehensive.value = true
                }
            } else {
                realname.value = ''
                isFromComprehensive.value = false
            }
        }
        // 真实姓名
        const realname = ref('')
        // 支付宝账号
        const alipayAccount = ref('')
        // 银行卡号
        const bankCard = ref('')
        // 银行名称
        const bankName = ref({
            name: '请选择银行',
            id: ''
        })
        // 选择银行
        const handleSelectBankAction = (item) => {
            bankName.value = item
        }
        // 只能输入中文
        const formatter = (value) => value.replace(/[^\u4e00-\u9fa5]/g, "");
        // 存储上传收款码得到的文件流
        const codeFileList = ref([])
        // 存储上传收款码得到的图片地址
        const codeUrl = ref('')
        // 上传收款码前
        const beforeUpload = () => {
            showOverlay.value = true
            codeFileList.value = []
            codeUrl.value = ''
            return true
        }
        // 上传收款码后
        const afterUpload = (file) => {
            // 此时可以自行将文件上传至服务器
            const formData = new FormData()
            formData.append('file', file.file)
            formData.append('file_name', 'collection')
            uploadImage(formData).then(res => {
                if(res.result == 200000) {
                    codeUrl.value = res.data.path
                    file.status = 'success'
                    showOverlay.value = false
                } else {
                    file.status = 'failed';
                    file.message = '上传失败';
                    showOverlay.value = false
                    codeFileList.value = []
                    Toast.fail(res.msg)
                }
            })
        };
        // 是否显示蒙层
        const showOverlay = ref(false)
        // 添加收付款方式
        const handleAdd = () => {
            if (btnClock.value) return
            if(!realname.value) {
                return Toast.fail('请输入真实姓名')
            }
            if(paymentWayIndex.value == 1) {
                if(!alipayAccount.value) {
                    return Toast.fail('请输入支付宝账号')
                }
            }
            if(paymentWayIndex.value == 3) {
                if(!bankCard.value) {
                    return Toast.fail('请输入银行卡号')
                } else if(bankName.value.name == '请选择银行') {
                    return Toast.fail('请选择银行')
                }
            }
            btnClock.value = true
            let params = {
                type: paymentWayIndex.value,
                name: realname.value,
                card: "",
                bank_name: "",
                status: 1
            }
            if(paymentWayIndex.value == 1) {
                params.card = alipayAccount.value
            }
            if(paymentWayIndex.value == 3) {
                params.card = bankCard.value
                params.bank_name = bankName.value.id
            }
            if(paymentWayIndex.value != 3) {
                params.bank_name = codeUrl.value
            }
            doAddPayment(params).then(res => {
                btnClock.value = false
                if(res.result == 200000) {
                    props.handleCloseAddPaymentDialog(true)
                    Toast.success('添加成功')
                } else {
                    Toast.fail(res.msg)
                }
            }).catch(error => {
                btnClock.value = false
                Toast.fail('操作失败，请检查网络重试或者联系客服')
            })
        }
        // 按钮锁
        const btnClock = ref(false)
        // mapstate--皮肤对应的图片
        const themeImgs = computed(() => store.state.themeImgs)
        return {
            showAddPaymentDialog,
            paymentWayIndex,
            handleChangeWay,
            showBankAction,
            bankActions,
            handleSelectBankAction,
            realname,
            alipayAccount,
            bankCard,
            bankName,
            handleAdd,
            codeFileList,
            afterUpload,
            formatter,
            btnClock,
            themeImgs,
            showOverlay,
            beforeUpload,
            isFromComprehensive
        }
    }
}
</script>

<style lang="less" scoped>
.addPayment-wrap {
    :deep(.addPayment-dialog) {
        width: 443px;
        height: 297px;
        top: 50%;
        padding-top: 53px;
        background: url('../../assets/images/payment/add-payment-bg.png') no-repeat;
        background-size: 100% 100%;
        border-radius: 3px;
        .close-btn {
            position: absolute;
            top: 24px;
            right: 0;
            width: 28px;
            height: 28px;
        }
        .addPayment-main-wrap {
            height: 244px;
            padding: 10px 50px;
            overflow-y: auto;
            h3 {
                padding-bottom: 8px;
                font-weight: 500;
                font-size: 16px;
                &.fill-title {
                    padding-top: 8px;
                }
            }
            .addPayment-type-box {
                display: flex;
                align-items: center;
                justify-content: space-around;
                height: 50px;
                background: #2B4F87;
                border-radius: 8px;
                .item-box {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 90px;
                    height: 38px;
                    &.active {
                        background: rgba(255, 255, 255, 0.2);
                        border: 1px solid #1371CA;
                        border-radius: 30px;
                        span {
                            color: #FFD65A;
                        }
                    }
                    .van-image {
                        width: 24px;
                        height: 24px;
                        margin-right: 8px;
                    }
                }
            }
            .common-info-wrap {
                padding: 16px 12px;
                background: #2B4F87;
                border-radius: 8px;
                .info-item {
                    display: flex;
                    align-items: center;
                    padding: 8px 0;
                    span {
                        font-size: 16px;
                        &.label {
                            width: 100px;
                            font-weight: 500;
                        }
                    }
                    .van-field {
                        flex: 1;
                        background: #0B1D4E;
                        border-radius: 8px;
                    }
                    .bank-box {
                        display: flex;
                        align-items: center;
                        justify-content: space-around;
                        min-width: 184px;
                        padding: 2px 0 2px 10px;
                        background: rgba(255, 255, 255, 0.5);
                        border: 1px solid #02318B;
                        border-radius: 30px;
                        .van-image {
                            width: 24px;
                            height: 24px;
                        }
                    }
                }
            }
            .add-btn-box {
                margin-top: 10px;
                .van-button {
                    width: 216px;
                    height: 31px;
                    margin: auto;
                    background: url('../../assets/images/common/btn-bg.png') no-repeat;
                    background-size: 100% 100%;
                    border-radius: 8px;
                }
            }
        }
    }
    .addPayment-overlay {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}
</style>